import '../../App.css';
import React,{Component,Fragment} from 'react';
import ThinSpinner from '../layout/ThinSpinner'
import PropTypes from 'prop-types';
import axios from 'axios';




class BusAlertItem extends Component {

   state = {
      loading: false,
      toggle: false,
      currentId: null,
      closeAlert: false
      // stateToggleArray: [],
   }

   alertDescription = React.createRef();
   busAlertRoute = React.createRef();
   busAlertStatus = React.createRef();
   //closeDescription = React.createRef();

   componentDidMount() {
      this.alertDescription['current'].style.display = 'none'
      const {alertsArray} = this.props;
      let precursorArray = [];
      let addPropertiesArray = []
      for(let i = 0; i < alertsArray.length; i++) {
         precursorArray.push({})
      }
      for(let i = 0; i < precursorArray.length; i++) {
         addPropertiesArray.push({toggle: false})
      }
      
      this.setState({stateToggleArray: addPropertiesArray})
      //this.closeDescription['current'].style.display = 'none'  
   }

   

   

   static propTypes = {
       //bus: PropTypes.object.isRequired
   }

   seeDetails = async (e,marker) => {
      const {alertsArray,toggleArray} = this.props;
      let childArray;
      this.setState({currentId: marker});
      let toggle = toggleArray[marker];
      if(toggle === 'false') {
         toggle = false
      }
      else {
         toggle = true
      }

      if(!toggle) {
        
         this.alertDescription['current'].style.display = 'block';
         this.busAlertRoute['current'].style.display = 'none'
         this.busAlertStatus['current'].style.display = 'none'
         if(e.target.className === 'busAlertItem') {
            childArray = Array.prototype.slice.call(e.target.parentElement.children);
            childArray.forEach((item,index) => {
               if(item.children[2].style.display === 'block' && index !== marker) {
                  item.children[0].style.display = 'inline-block'
                  item.children[1].style.display = 'inline-block'
                  item.children[2].style.display = 'none'
               }
            })
         }
   
         else if(e.target.id === 'busAlertRoute') {
            childArray = Array.prototype.slice.call(e.target.parentElement.parentElement.children);
            childArray.forEach((item,index) => {
               if(item.children[2].style.display === 'block' && index !== marker) {
                  item.children[0].style.display = 'inline-block'
                  item.children[1].style.display = 'inline-block'
                  item.children[2].style.display = 'none'
               }
            })
         }
   
         else if(e.target.id === 'busAlertStatus') {
            childArray = Array.prototype.slice.call(e.target.parentElement.parentElement.children);
            childArray.forEach((item,index) => {
               // console.log(item.children[2].style.display)
               // console.log(item.children[2])
               if(item.children[2].style.display === 'block' && index !== marker) {
                  //console.log(item[index].children[2])
                  item.children[0].style.display = 'inline-block'
                  item.children[1].style.display = 'inline-block'
                  item.children[2].style.display = 'none'

               }
            })
         }
          //SETTING TOGGLE TO TRUE 
          toggle = true;
          toggleArray[marker] =  toggle;
         this.props.seeDetails(this.busAlertRoute['current'].innerText,toggleArray[marker],marker);
      }

      else {
         this.alertDescription['current'].style.display = 'none';
         this.busAlertRoute['current'].style.display = 'inline-block'
         this.busAlertStatus['current'].style.display = 'inline-block'
         if(toggle === true) {
            toggle ='false'
         }

         toggleArray[marker] =  toggle;
         //this.setState({stateToggleArray: toggleArray})  
         this.props.seeDetails(this.busAlertRoute['current'].innerText,toggleArray[marker],marker);
      }
      
      e.stopPropagation(); 
   }

  

   
  
  

   render() {
      const {alert,shortDescription,marker} = this.props;
      return (
        <div onClick={(e) => {this.seeDetails(e,marker)}} className='busAlertItem'>
           <div ref={this.busAlertRoute} id='busAlertRoute'>{alert.Route}</div>
           <div ref={this.busAlertStatus} id='busAlertStatus'>{alert.RouteStatus}</div>
           <div ref={this.alertDescription} id='alertDescription'>{shortDescription}</div>
        </div>
      ) 
   }
}

export default BusAlertItem;
