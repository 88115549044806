import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';




class TrainLines extends Component {

    
   state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
      
   }

   chooseLine = (e) => {
        let line;
        if(e.target.innerText === 'blue line') {
            line = 'Blue'
            this.props.chooseLine(line)
        }
        else if(e.target.innerText === 'red line') {
            line = 'Red'
            this.props.chooseLine(line)
        }
        else if(e.target.innerText === 'brown line') {
            line = 'Brn'
            this.props.chooseLine(line)
        }
        else if(e.target.innerText === 'green line') {
            line = 'G'
            this.props.chooseLine(line)
        }
        else if(e.target.innerText === 'orange line') {
            line = 'Org'
            this.props.chooseLine(line)
        }
        else if(e.target.innerText === 'purple line') {
            line = 'P'
            this.props.chooseLine(line)
        }
        else if(e.target.innerText === 'pink line') {
            line = 'Pink'
            this.props.chooseLine(line)
        }
        else {
            line = 'Y'
            this.props.chooseLine(line)
        }
        
        //this.props.chooseLine(e.target.innerText);
   }

   
  
  

   render() {
    return (
        <div id='trainLinesContainer'>
            <div onClick={this.chooseLine} id='blueLineContainer' className='trainButton'>
                blue line
            </div>
            <div  onClick={this.chooseLine} id='redLineContainer' className='trainButton'>
                red line
            </div>
            <div  onClick={this.chooseLine} id='brownLineContainer' className='trainButton'>
                brown line
            </div>
            <div  onClick={this.chooseLine} id='greenLineContainer' className='trainButton'>
                green line
            </div>
            <div  onClick={this.chooseLine} id='orangeLineContainer' className='trainButton'>
                orange line
            </div>
            <div  onClick={this.chooseLine} id='purpleLineContainer' className='trainButton'>
                purple line
            </div>
            <div  onClick={this.chooseLine} id='pinkLineContainer' className='trainButton'>
                pink line
            </div>
            <div  onClick={this.chooseLine} id='yellowLineContainer' className='trainButton'>
                yellow line
            </div>
        </div>
    )

   }
}

// const fullHeight = {
//     height: '100%'
// }









export default TrainLines;