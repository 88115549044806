import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../App.css';

class RouteItem extends Component {

    state = {
        chosenBusRoute: ''
    }
 
    static propTypes = {
        route: PropTypes.object.isRequired
    }

    clickBusRoute = (e) => {
        let routeNumber;
        if(e.target.className === 'busRouteName') {
            routeNumber = e.target.previousSibling.innerText
        }

        else if(e.target.className === 'busRouteItemContainer') {
            console.log(e.target.firstElementChild.innerText)
            routeNumber = e.target.firstElementChild.innerText
        }


        else {
            routeNumber = e.target.innerText;
            //routeNumber = e.target.previousSibling.innerText
            console.log(routeNumber)
        }
        //USE THE SETSTATE CALLBACK FUNCTION
        this.setState({chosenBusRoute: routeNumber}, function() {
            //this.props.clickBusRoute(this.state.chosenBusRoute)
            //this.props.clickBusRoute(routeNumber)
        }) 
        this.props.clickBusRoute(routeNumber) 
    }

    
 
    
    render() {
        const{rt, rtnm} = this.props.route
        return (
            <div onClick={this.clickBusRoute} className='busRouteItemContainer'>
                <div style={routeNumber} className='busRouteNumber'>{rt}</div>
                <div style={routeName}className='busRouteName'>{rtnm}</div>
            </div>
        )
    }
}

const routeNumber = {
    // border: '1px solid black'
}

const routeName = {
    // border: '1px solid black'
}


export default RouteItem;