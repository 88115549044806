import React from 'react';
import {Icon} from '@iconify/react';
import BusIcon from '@iconify/icons-mdi/bus-side';


const BusMarker = () => {
    return (
        <div>
            <Icon icon={BusIcon} className='location-icon'></Icon>
        </div>
    )
}

export default BusMarker
