import '../../App.css';
import React,{Component,Fragment} from 'react';
import ThinSpinner from '../layout/ThinSpinner'
import PropTypes from 'prop-types';
import axios from 'axios';




class TrainAlertItem extends Component {

    
   state = {
      loading: false,
      toggle: false,
      trainAlerts: null
   }

   //alertDescription = React.createRef();
   trainAlertRoute = React.createRef();
   trainAlertStatus = React.createRef();
   trainAlertDescription = React.createRef();
   //closeDescription = React.createRef();

   componentDidMount() {
      //this.alertDescription['current'].style.display = 'none'
      //this.closeDescription['current'].style.display = 'none'
      this.trainAlertRoute['current'].style.display = 'inline-block'
      this.trainAlertStatus['current'].style.display = 'inline-block'
      this.trainAlertDescription['current'].style.display = 'none'
       
   }

   static propTypes = {
       //bus: PropTypes.object.isRequired
   }

   seeDetails = async (e) => {
      console.log('seeing details')
      let toggle = this.state.toggle;
      if(!toggle) {
         console.log('toggle is false')
         this.alertDescription['current'].style.display = 'block';
         this.busAlertRoute['current'].style.display = 'none'
         this.busAlertStatus['current'].style.display = 'none'
         this.props.seeDetails(this.busAlertRoute['current'].innerText);
         toggle = true;
         this.setState({toggle: toggle})  
      }

      else {
         this.alertDescription['current'].style.display = 'none';
         this.busAlertRoute['current'].style.display = 'inline-block'
         this.busAlertStatus['current'].style.display = 'inline-block'
         toggle = false;
         this.setState({toggle: toggle})  
      }
   }

   seeTrainDetails = async () => {
      //HIDE WHEN CLICKED
      this.trainAlertRoute['current'].style.display = 'none'
      this.trainAlertStatus['current'].style.display = 'none'
      //DISPLAY DESCRIPTION 
      this.trainAlertDescription['current'].style.display = 'block'
      
      let trainRoute = this.trainAlertRoute['current'].innerText;
      const {trainAlertsArray,test} = this.props;
      
      
      let chosenAlert = trainAlertsArray.filter((alert) => {
        return alert.Route === trainRoute
      })
      let routeId = chosenAlert[0].ServiceId;
  
      const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.transitchicago.com/api/1.0/alerts.aspx?routeid=${routeId}&outputType=JSON`)
      let convert = res.data;
      let isolatedArray = convert['CTAAlerts']['Alert'];
      this.setState({trainAlerts: isolatedArray})  
   }

   seeTrainDescription = (e) => {
       //HIDE WHEN CLICKED
       this.trainAlertDescription['current'].style.display = 'none'
       
       //DISPLAY DESCRIPTION 
       this.trainAlertRoute['current'].style.display = 'inline-block'
       this.trainAlertStatus['current'].style.display = 'inline-block'
      e.stopPropagation()

   }

   
  
  

   render() {
      const {alert} = this.props;
      return (
        <div onClick={this.seeTrainDetails} className='trainAlertItem'>
           <div ref={this.trainAlertRoute} id='trainAlertRoute'>{alert.Route}</div>
           <div ref={this.trainAlertStatus} id='trainAlertStatus'>{alert.RouteStatus}</div>
           <div onClick={this.seeTrainDescription} ref={this.trainAlertDescription}>
               {this.state.trainAlerts === null ? null : this.state.trainAlerts.map((description,index) => (
                  <div style={alertUnit} key={index}>
                     <div style={headLine} >{description.Headline}</div>
                     <div>{description.ShortDescription}</div>
                  </div>
               ))} 
           </div>
        </div>
      ) 
   }
}

const alertUnit = {
   paddingBottom: '20px'
}

const headLine = {
   borderBottom: '1px solid black',
   paddingBottom: '10px',
   textTransform: 'uppercase',
   color: 'red'
}

export default TrainAlertItem;
