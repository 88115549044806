import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';




class BusArrivalItem extends Component {
  
     state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
       bus: PropTypes.object.isRequired
   }

   
  
  

   render() {
      const {bus} = this.props;
      let over = false;
      let removeMin = false;
      let minutesToArrival = bus.prdctdn
      minutesToArrival += ' MIN';

      if(bus.prdctdn === 'DLY') {
        minutesToArrival = 'DELAY';
        over = true;
      }

      if(bus.prdctdn > 5) {
        over = true
      }

      if(bus.prdctdn === 'DUE') {
        removeMin = true;
      }

     

      return (
        // {bus.prdctdn}
        <div className='busArrivalItem'>
            <div style={over?overFiveMinutes:arrivingSoon} id='arrivalTime'> {removeMin? "DUE": minutesToArrival}</div>
            <div id='arrivalDestination'>To: {bus.des}</div>
            <div id='arrivalNumber'>vehicle #: {bus.vid}</div>
            <div id='arrivalHeading'>Heading: {bus.rtdir}</div>
            <div id='arrivalFeet'>Your luxury vehicle is {bus.dstp} feet away</div>
        </div>
      ) 

    
   }
}

const arrivingSoon = {
   color: '#2d8a13'
}

const overFiveMinutes = {
  color: '#ad211c'
}

export default BusArrivalItem;
