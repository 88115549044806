import React from 'react';
import {Icon} from '@iconify/react';
import TrainIcon from '@iconify/icons-mdi/train';


const TrainMarker = () => {
    return (
        <div>
            <Icon icon={TrainIcon} className='location-icon'></Icon>
        </div>
    )
}

export default TrainMarker
