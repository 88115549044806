import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';



class NoService extends Component {


   state = {

   }

   componentDidMount() {

   }

   static propTypes = {
     serviceMessage: PropTypes.string.isRequired
   }





   render() {
       const {serviceMessage} = this.props;

       return (
        <div style={noServiceStyleContainer}>
            <div style={noServiceStyle}>
                 <div>{serviceMessage}</div>
                 <div style={noServiceStyleContainer}>
                  <i style={icon} className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
            </div>
          
        </div>
       )


   }
}

const noServiceStyleContainer = {
     height: '100vh'
}


const noServiceStyle = {
    fontFamily: "Raleway",
    fontSize: '1.4em',
    textAlign: 'center',
    color: '#ad211c',
    paddingTop: '40px',
    fontWeight: 'bolder',
    backgroundColor: '#94baf7',

}

const icon = {
    display: 'block',
    color: '#ad211c',
    textAlign: 'center',
    backgroundColor: '#94baf7',
    fontSize: '5em',
    paddingTop: '30px'
}

export default NoService;
