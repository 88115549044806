import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BusMarker from './BusMarker';

export class Map extends Component {


    state = {
        
    }
    componentDidMount() {
      
    }

    

    static defaultProps = {
        zoom: 16
    };
 
    render() {
        let extractor = {};
        let loopFinished = false;
        const {busDepot} = this.props;
        // center={}
        if(busDepot.length === 0) {
            console.log('still getting data')
        }
        else {
            
        }
        console.log(busDepot)
        //GET FIRST ELEMENT LAT AND LONG
        for(let i = 0; i < busDepot.length; i++) {
           if(i === 0) {
                extractor.lat = parseFloat(busDepot[i].lat);
                extractor.lng = parseFloat(busDepot[i].lon);
                loopFinished = true;
           }
        }

        if(loopFinished) {
            console.log(extractor)
        }
        
        const busPoints = busDepot.map((bus,item) => {
            return <BusMarker key={item} lat={bus.lat} lng={bus.lon}></BusMarker>
        })
        
        return (    
            <div className='map'>
                <GoogleMapReact bootstrapURLKeys={{key: 'AIzaSyD_ytJJqN_C44GTmLVwM8VQzLBaT46XMmA'}} center={extractor}
             defaultZoom={Map.defaultProps.zoom}>
                 {busPoints}
                </GoogleMapReact>
            </div>
        )
    }
}

export default Map
