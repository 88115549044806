import '../../App.css';
import React,{Component,Fragment} from 'react';
import RouteItem from './RouteItem';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';


class BusRoutes extends Component {


   state = {
       route: {},
    //    stateBusRoutes: []
   }

   componentDidMount() {
       const {busRoutes,loading,clickBusRoute} = this.props;
    //    this.setState({stateBusRoutes: busRoutes})
   }

   static propTypes = {
       busRoutes: PropTypes.array.isRequired,
       loading: PropTypes.bool.isRequired,
       clickBusRoute: PropTypes.func.isRequired
   }

   clickBusRoute = (routeNumber) => {
    const {busRoutes} = this.props;
    //   let selectedRoute = this.state.stateBusRoutes.find((route) => {
    //       return route.rt === routeNumber.trim()
    //   })
      let selectedRoute = busRoutes.find((route) => {
        return route.rt === routeNumber.trim()
    })
      console.log(routeNumber)
      this.props.clickBusRoute(selectedRoute);
   }

   newStop = () => {
    this.props.newStop(false)
   }

   render() {
       const {busRoutes,loading,clickBusRoute,root} = this.props;
       root.style.backgroundColor = '#fff'; 

       if(loading) {
        return (
            <Spinner></Spinner>
        )

       }

        else {
            return (
                <div id='busRouteContainer'>
                    {busRoutes.map(route => (
                        <RouteItem key={route.rt} route={route} clickBusRoute={this.clickBusRoute}></RouteItem>
                    ))}
                </div>
            ) 
        }

   }
}




export default BusRoutes;
