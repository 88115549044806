import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';



class TrainArrivalItem extends Component {
   state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
      time: PropTypes.object.isRequired
   }
   render() {
      const {time,color} = this.props
      let arriving = false;
      let due = false;
      let minutesLeft;
      let predictionTime = time.prdt
      let predictionMinutes = new Date(predictionTime).getMinutes();
      let arrivalTime = time.arrT
      let arrivalMinutes = new Date(arrivalTime).getMinutes();
      console.log(arrivalMinutes)
      console.log(typeof(arrivalMinutes))
      if(arrivalMinutes === 0) {
         arrivalMinutes = 60;
      }
      
      if(predictionMinutes > arrivalMinutes) {
         minutesLeft = predictionMinutes - arrivalMinutes;
         minutesLeft = 60 - predictionMinutes + (arrivalMinutes - 0);
         console.log(minutesLeft)
      }

      else {
         minutesLeft = arrivalMinutes - predictionMinutes;
         console.log(minutesLeft)
      }
      

      if(minutesLeft <= 5) {
         arriving = true;
      }

      else {
         arriving = false
      }

      if(minutesLeft < 2) {
         due = true;
      }

      return (
          <div className='trainArrivalItem'>
             <div style={{borderBottom: `4px solid ${color}`}} className='destination'>{time.stpDe}</div>
             <div className='trainRun'># {time.rn}</div>
             <div className='minutesToArrive' style={arriving?arrivingSoon:overFiveMinutes}>{due?'DUE':minutesLeft} {due?null:'MIN'}</div>
          </div>
      )

   }
}

const arrivingSoon = {
   color: '#2d8a13'
}

const overFiveMinutes = {
  color: '#ad211c'
}




export default TrainArrivalItem;
