import '../../App.css';
import React,{Component,Fragment} from 'react';
import axios from 'axios';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';
import BusAlertItem from './BusAlertItem';
import TrainAlertItem from './TrainAlertItem';

class ServiceBulletin extends Component {

    
   state = {
      busIconOpen: null,
      busToggle: null,
      trainIconOpen: null,
      trainToggle: null,
      loading: null,
      busAlertsArray: null,
      description: null,
      miniLoading: null,
      trainAlertsArray: null,
      trainAlertDetailsArray: null,
      test: null,
      toggleArray: null,
      height: null,
      screenHeight: null,
      closeAlertsState: false
   }

   busAlertPanel = React.createRef();
   trainAlertPanel = React.createRef();
   stationAlertPanel = React.createRef();

   componentDidMount() { 
    const {referenceToContainer} = this.props;
    this.setState({busIconOpen: false})
    this.setState({busToggle: false})
    this.setState({trainIconOpen: false})
    this.setState({trainToggle: false})
    this.setState({loading: false})
    this.busAlertPanel['current'].style.display = 'none'
    //this.setState({busAlertPanelState: this.busAlertPanel})
    this.trainAlertPanel['current'].style.display = 'none'
    referenceToContainer.style.height = '700px'
    // this.stationAlertPanel['current'].style.display = 'none'
    
   }
   static propTypes = {
     
   }

   displayBusAlerts = async () => {
     let busToggle = this.state.busToggle;
     let toggle = {}
     this.setState({loading: true})
     const {referenceToContainer} = this.props;
     if(!busToggle) {
       referenceToContainer.style.height = '1600px'
       this.setState({busIconOpen: true})
       this.setState({busToggle: true})
       this.busAlertPanel['current'].style.display = 'block';
       let height = document.getElementById('busAlertPanel').clientHeight;
       const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.transitchicago.com/api/1.0/routes.aspx?type=bus&outputType=JSON`)
      let convert = res.data;
      let busAlertsArray = convert['CTARoutes']['RouteInfo'];
      let alertAbnormal = busAlertsArray.filter((alert) => {
        return alert.RouteStatus !== 'Normal Service'
      })
      let toggleArray = []
      //ADD TOGGLE TO ALERT ABNORMAL
      for(let i = 0; i < alertAbnormal.length; i++) {
        toggleArray.push(toggle.toggle = 'false')
        //console.log(i)
      }

       this.setState({loading: false})
       this.setState({toggleArray: toggleArray})
      
       this.setState({busAlertsArray: alertAbnormal})

     }
     else {
      referenceToContainer.style.height = '800px'
      this.setState({busIconOpen: false})
      this.setState({busToggle: false})
      this.busAlertPanel['current'].style.display = 'none'
     }
   }

   displayTrainAlerts = async () => {
    let trainToggle = this.state.trainToggle;
    this.setState({loading: true})
    const {referenceToContainer} = this.props;
    if(!trainToggle) {
      referenceToContainer.style.height = '1600px'
      this.setState({trainIconOpen: true})
      this.setState({trainToggle: true})
      this.trainAlertPanel['current'].style.display = 'block'
      const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.transitchicago.com/api/1.0/routes.aspx?type=rail&outputType=JSON`)
      let convert = res.data;
      let trainAlertsArray = convert['CTARoutes']['RouteInfo'];
      let alertAbnormal = trainAlertsArray.filter((alert) => {
        return alert.RouteStatus !== 'Normal Service'
      })
      this.setState({loading: false})
       this.setState({trainAlertsArray: alertAbnormal})
    }
    else {
     this.setState({trainIconOpen: false})
     this.setState({trainToggle: false})
     this.trainAlertPanel['current'].style.display = 'none'
    }
  }

  displayStationAlerts = async () => {
    let stationToggle = this.state.stationToggle;
    if(!stationToggle) {
      console.log('opening')
      this.setState({stationIconOpen: true})
      this.setState({stationToggle: true})
      this.stationAlertPanel['current'].style.display = 'block'
      const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.transitchicago.com/api/1.0/routes.aspx?type=station&outputType=JSON`)
      let convert = res.data;
      console.log(convert)
    }
    else {
     console.log('closing')
     this.setState({stationIconOpen: false})
     this.setState({stationToggle: false})
     this.stationAlertPanel['current'].style.display = 'none'
    }
  }

  seeDetails = async (route,choice,marker) => {
    let busAlerts = this.state.busAlertsArray;
    let toggleArrayFromState = this.state.toggleArray;
    toggleArrayFromState[marker] = choice;
    //here set all items to false in array not chosen
    for(let i = 0; i < toggleArrayFromState.length; i++) {
      if(i !== marker){
        toggleArrayFromState[i] = 'false'
      }
    }
    this.setState({toggleArray: toggleArrayFromState})
    let chosenAlert = busAlerts.filter((alert) => {
      return alert.Route === route
    })
    let routeId = chosenAlert[0].ServiceId;

    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.transitchicago.com/api/1.0/alerts.aspx?routeid=${routeId}&outputType=JSON`)
    let convert = res.data;
    let isolatedArray = convert['CTAAlerts']['Alert'];
    let description = isolatedArray[0].ShortDescription;
    this.setState({description: description})
  }

  openStops = (msg) => {
    console.log(msg)
  }

 
   render() {
      const {root,referenceToContainer} = this.props;
      let busArrowUp = 'fa fa-caret-up';
      let busArrowDown = 'fa fa-caret-down';
      let trainArrowUp = 'fa fa-caret-up';
      let trainArrowDown = 'fa fa-caret-down';
      let stationArrowUp = 'fa fa-caret-up';
      let stationArrowDown = 'fa fa-caret-down';
    
     root.style.backgroundColor = '#94baf7';
     

       

        return (
          <div id='serviceBulletinContainer'>
              <div id='busAlertButton'>
                  <div id='busAlertText' 
                  onClick={this.displayBusAlerts}>Bus Alerts 
                  <i className={this.state.busIconOpen?busArrowUp:busArrowDown}aria-hidden="true"></i>
                  </div>
              </div>
              <div id='busAlertPanel' ref={this.busAlertPanel}>
                {
                  this.state.loading?<Spinner></Spinner> : this.state.busAlertsArray !== null ?this.state.busAlertsArray.map((alert,index) => (
                    <BusAlertItem key={index} 
                    alert={alert} marker={index} seeDetails={this.seeDetails} 
                    shortDescription={this.state.description} 
                    alertsArray={this.state.busAlertsArray} 
                    toggleArray={this.state.toggleArray}></BusAlertItem>
                  )):null
                }
              </div>
              <div id='railAlertButton'>
                  <div id='railAlertText' 
                  onClick={this.displayTrainAlerts}>Rail Alerts
                  <i className={this.state.trainIconOpen?busArrowUp:busArrowDown}aria-hidden="true"></i>
                  </div>
              </div>
              <div id='railAlertPanel' ref={this.trainAlertPanel}>
                {
                  this.state.loading?<Spinner></Spinner>: this.state.trainAlertsArray !== null ? this.state.trainAlertsArray.map((alert,index) => (
                    <TrainAlertItem key={index} alert={alert}  
                    trainAlertsArray={this.state.trainAlertsArray} trainAlertDetails={this.state.trainAlertDetailsArray}></TrainAlertItem>
                  )): null
                }
              </div>
              {/* <div id='stationAlertButton'>
                  <div id='stationAlertText' 
                  onClick={this.displayStationAlerts}>Station Alerts
                  <i className={this.state.stationIconOpen?stationArrowUp:stationArrowDown}aria-hidden="true"></i>
                  </div>
              </div>
              <div id='stationAlertPanel' ref={this.stationAlertPanel}>
                will show bus alerts
                will show bus alerts
                will show bus alerts
                will show bus alerts
                will show bus alerts
                will show bus alerts
              </div> */}
          </div>
        )
   }
}

// const stationTitle = {
//     fontFamily: 'Raleway',
//     fontSize: '2em',
//     textAlign: 'center'
// }

// const newIconContainer = {
//   textAlign: 'center',
//   fontFamily: 'Raleway',
//   fontWeight: 'bolder',
//   paddingTop: '20px',
//   paddingBottom: '15px',
//   fontSize: '1.2em'
// }

// const mapIcon = {
//   fontSize: '1.6em',
//   color: 'black'
// }

export default ServiceBulletin;
