import React from 'react';
import '../../App.css';
import PropTypes from 'prop-types';
import reactLogo from '../../reactMaster.png';
import axiosLogo from '../../axiosMaster.png';
import photo from '../../meimage.jpg';


const About = (props) => {

     //DESTRUCTURING
     //aboutRef = React.createRef();

    //props.referenceToContainer.style.backgroundColor = '#94baf7'
  
     return (
         <div id='aboutContainer'>
             <div id='aboutTitle'>This web application was developed using... </div>
             <div id='logo'><img style={{width:'70%', display: 'block'}}  src={reactLogo} alt="" /></div>
             <div id='axiosLogo'><img style={{width:'70%', display: 'block'}}  src={axiosLogo} alt="" /></div> 
             <div id='credits' style={{textAlign: 'center',fontSize:'1.4em', paddingBottom:'10px'}}>Developed by Adam Lopez</div>
             {/* <div id='myPhoto'><img style={{width:'70%', display: 'block',borderRadius:'50px',paddingBottom:'10px'}}  src={photo} alt="" /></div> */}
         </div>
     ) 
}


About.propTypes = {
    
}

export default About;