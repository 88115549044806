import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react';
import TrainMarker from './TrainMarker';
import ErrorMap from './ErrorMap';

export class TrainMap extends Component {

    state = {
        
    }
    
    componentDidMount() {
      
    }

    static defaultProps = {
        zoom: 16
    };

    
    render() {
        const {trainDepot} = this.props
        let showErrorMap = false;
        let extractor = {};
        for(let i = 0; i < trainDepot.length; i++) {
            if(i === 0) {
                 extractor.lat = parseFloat(trainDepot[i].lat);
                 extractor.lng = parseFloat(trainDepot[i].lon);
            }
         }
        console.log(trainDepot)
        console.log(extractor)
        if(extractor.lat === NaN && extractor.lng === NaN) {
            console.log('nan returned')
        }
        const trainPoints = trainDepot.map((train,item) => {
            return <TrainMarker key={item} lat={train.lat} lng={train.lon}></TrainMarker>
        })
        console.log(trainPoints)
        console.log(trainPoints[0]['props'].lat)
        if(trainPoints[0]['props'].lat === null) {
            console.log('show error map')
            showErrorMap = true;
        }
        return (
            <div className='trainMap'>
                  {showErrorMap?<ErrorMap></ErrorMap>: <GoogleMapReact bootstrapURLKeys={{key: 'AIzaSyD_ytJJqN_C44GTmLVwM8VQzLBaT46XMmA'}} center={extractor}
             defaultZoom={TrainMap.defaultProps.zoom}>
                 {trainPoints}
                </GoogleMapReact>}
                  {/* <GoogleMapReact bootstrapURLKeys={{key: 'AIzaSyD_ytJJqN_C44GTmLVwM8VQzLBaT46XMmA'}} center={extractor}
             defaultZoom={TrainMap.defaultProps.zoom}>
                 {trainPoints}
                </GoogleMapReact> */}
            </div>
        )
    }
}

export default TrainMap
