import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';
import BusStopItem from './BusStopItem';



class BusStops extends Component {

    
   state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
       loading: PropTypes.bool.isRequired,
      
   }

   chooseStop = (chosenStop) => {
    const {ctaBusStops} = this.props   
    let selectedStop = ctaBusStops.find((stop) => {
        return chosenStop === stop.stpnm.trim();
    }) 
    let stopId = selectedStop.stpid;
    this.props.chooseStop(stopId,chosenStop)
   }

  
  

   render() {
      const {loading, chosenRoute, ctaBusStops} = this.props;

       if(loading) {
        return (
            <Spinner></Spinner>
        )

       }

        else {
            return (
                <div id='busStopContainer'>
                   <h1 className='headline'>{chosenRoute.rtnm}</h1>
                   <h3 style={stopStyle}>Choose Stop</h3>
                   {ctaBusStops.map((stop, index) => (
                       <BusStopItem key={index} stop={stop} chooseStop={this.chooseStop}></BusStopItem>
                   ))}                   
                </div>
            ) 
        }

   }
}


const stopStyle = {
    color: '#263237',
    fontFamily: "Raleway",
    textAlign: 'center'
}






export default BusStops;
