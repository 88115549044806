import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import spinner from './spinner.gif';

const Spinner = (props) => {

     //DESTRUCTURING
     //width 200px
     return (
        <img src={spinner} alt="Loading" style={{width: '200px', margin: 'auto', display:'block'}}/>
     ) 
}


Spinner.propTypes = {
    
}

export default Spinner;