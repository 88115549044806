import React from 'react'

const ErrorMap = () => {
    return (
        <div id='mapErrorMsg' style={{textAlign:'center'}}>
            Something Happened Loading Map Fool, Sorry
        </div>
    )
}

export default ErrorMap
