import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';
import BusArrivalItem from './BusArrivalItem';
import Map from './Map'
import axios from 'axios';




class BusArrivals extends Component {

    constructor(props) {
        super(props)
    }
   state = {
       arrivingBusesState: null,
       busLatLong: null
   }

   componentDidMount() {
      
   }



   static propTypes = {
       loading: PropTypes.bool.isRequired,
       chosenRoute: PropTypes.object.isRequired
   }

   refreshTime = () => {
    //    console.log('refreshing time')
       this.props.refreshTime('refreshing time')
   }

   newStop = () => {
       this.props.newStop(false)
   }

   render() {
      const {loading,arrivingBuses,chosenRoute,revealNoService,chosenStop,root,referenceToContainer,busCoordinates} = this.props;
      console.log(revealNoService)
      //let busIdArray = [];
       
      let busDepot = []
      if(busCoordinates !== null) {
        busCoordinates.map((bus) => {
            console.log(bus.data['bustime-response']['vehicle'][0])
            busDepot.push(bus.data['bustime-response']['vehicle'][0])
            console.log(busDepot)
        })
      } 

      if(busDepot.length > 0) {
          console.log('bus depot primed')
          
      }
      if(arrivingBuses !== null) {
        console.log(arrivingBuses)
      }
      console.log(loading)
      
       if(loading) {
        root.style.backgroundColor = '#fff'
        return (
            <Spinner></Spinner>
        )
       }

        else {
            return (
               <div style={revealNoService? null: fullHeight} id='busArrivalContainer'>
                   {/* <h1 className='headline'>{chosenRoute.rtnm}</h1> */}
                   <h1 className='headline'>{chosenStop}</h1>
                   <div onClick={this.newStop} style={newIconContainer}>New Stop <i style={mapIcon} className="fa fa-map" aria-hidden="true"></i></div>
                   <div style={refreshIcon} onClick={this.refreshTime}><i className="fa fa-refresh" aria-hidden="true"></i></div>
                  {revealNoService?null:arrivingBuses.map((bus, index) => (
                      <BusArrivalItem key={index} bus={bus}></BusArrivalItem>
                  ))}
                  {revealNoService?null:<Map busDepot={busDepot}></Map>}
                  {/* <Map busDepot={busDepot}></Map> */}
               </div>
            ) 
        }

   }
}

const fullHeight = {
    height: '100%'
}

const refreshIcon = {
    textAlign: 'center',
    fontSize: '2em',
    paddingTop: '15px'
}

const newIconContainer = {
    textAlign: 'center',
    fontFamily: 'Raleway',
    fontWeight: 'bolder',
    paddingTop: '20px',
    fontSize: '1.2em',
}

const mapIcon = {
    fontSize: '1.6em',
    color: 'rgb(146, 47, 44)'
}

export default BusArrivals;
