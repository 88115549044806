import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';




class TrainStops extends Component {

    
   state = {
      stationName: ''
   }

   componentDidMount() {
       
   }

   static propTypes = {
      trainStops: PropTypes.array.isRequired
   }

   chooseTrainStop = (e) => {
    let stopName = e.target.innerText
    const {trainStops} = this.props   
    let selectedStop = trainStops.find((stop) => {
        return stopName.trim() === stop.station.name;
    }) 
    let stationNumber = selectedStop.station.stationNumber;
    this.props.chooseTrainStop(stationNumber,stopName);
   }

   

   
   render() {
      const {trainStops, background} = this.props;
      let height;
      if(background === 'yellow') {
          height = '100vh'
      }
      else {
          height = null;
      }

    return (
        <div id='trainStopsContainer' style={{backgroundColor: background, height: height}}>
            {trainStops.map((stop, index) => (
                <div onClick={this.chooseTrainStop} 
                className='stationName'
                 key={index}>{stop.station.name === `O'hare`?<i className="fa fa-plane" aria-hidden="true"></i>:null} {stop.station.name}</div>
            ))} 
        </div>
    )

   }
}

export default TrainStops;