import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';



class BusStopItem extends Component {

    
   state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
      stop: PropTypes.object.isRequired
   }

   chooseStop = (e) => {
      this.props.chooseStop(e.target.innerText)
   }

  
  

   render() {
       const {stop} = this.props
      return (
          <div onClick={this.chooseStop} className='busStopItem'>{stop.stpnm}</div>
      )

   }
}




export default BusStopItem;
