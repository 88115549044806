import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';
import TrainArrivalItem from './TrainArrivalItem';
import TrainMap from './TrainMap';





class TrainArrivals extends Component {

    
   state = {
      
   }

   componentDidMount() {
     
       
   }

   static propTypes = {
     trainArrivalTimes: PropTypes.array.isRequired,
     stationName: PropTypes.string.isRequired
   }

   refreshTrains = () => {

     this.props.refreshTrains('message')
   }

//    refreshTime = () => {
//        console.log('refreshing time')
//        this.props.refreshTime('refreshing time')
//    }

   newStop = () => {
       console.log('new stop')
       this.props.newStop(false)
   }
   render() {
      const {trainArrivalTimes,stationName,background,root,loading,referenceToContainer} = this.props;
      root.style.backgroundColor = background;
      console.log(trainArrivalTimes)
      referenceToContainer.style.height = 'auto'

        if(loading) {
          root.style.backgroundColor = '#fff';
          return (<Spinner></Spinner>)
        }
        else {
          return (
            <div id='trainArrivalContainer' style={{backgroundColor: background}}>
            <h1 style={stationTitle}> {stationName.trim() ===`O'hare` ?<i className="fa fa-plane" aria-hidden="true"></i>:null } {stationName}</h1>
            <div id='newTrainContainer' style={newIconContainer} onClick={this.newStop}>New Stop <i style={mapIcon} className="fa fa-map" aria-hidden="true"></i></div>
            <div id='refreshTrains' onClick={this.refreshTrains}><i className="fa fa-refresh" aria-hidden="true"></i></div>
            {trainArrivalTimes.map((time,index) => (
                <TrainArrivalItem style={{backgroundColor: background}} key={index} time={time} color={background}></TrainArrivalItem>
            ))}
            <TrainMap trainDepot={trainArrivalTimes}></TrainMap>
          </div>
          )
        }
   }
}

const stationTitle = {
    fontFamily: 'Raleway',
    fontSize: '2em',
    textAlign: 'center'
}

const newIconContainer = {
  textAlign: 'center',
  fontFamily: 'Raleway',
  fontWeight: 'bolder',
  paddingTop: '20px',
  paddingBottom: '15px',
  fontSize: '1.2em'
}

const mapIcon = {
  fontSize: '1.6em',
  color: 'black'
  // color: 'rgb(146, 47, 44)',
}











export default TrainArrivals;
