import React, { Fragment, useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Spinner from './Spinner'
import '../../App.css';

const Navbar = (props) => {

     const userRoute = [];
     

     let stopReference = document.getElementById('stopsLink');

     //DESTRUCTURING
     const {snapShot, chosenRoute, openTabOne, routeCounter, savedRoutes, savedStops, direction, savedTrainStops,referenceToBody,serviceClicked} = props;
     const openStops = (e) => {
        // console.log(loading)
        //<i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
        //<i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
        //<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
        //<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>

         let navElement = e.target.nextElementSibling
         navElement.style.width = '100%';
         e.target.parentElement.parentElement.parentElement.style.overflowY = 'hidden'
         generateColor()
         //e.target.parentElement.style.width = '100%';
         //fromLocalStorage();
         props.openStops('message');
        //  props.referenceToContainer.style.overflowY = 'hidden';
            referenceToBody.style.overflowY = 'hidden'
            props.referenceToContainer.style.height = 'auto';         
         //props.referenceToContainer.style.height = '400px'
     }

     const closeStops = (e) => {
         let displayStopLink;
         let overlay = e.target.parentElement.parentElement.parentElement.parentElement;
         overlay.parentElement.parentElement.parentElement.style.overflowY = 'auto'
         overlay.style.width = '0';
         if(savedStops.length === 0 && savedTrainStops === 0) {
            console.log('no more saved stops')
            displayStopLink = false;
            props.closeStops(displayStopLink);
        }
        props.referenceToContainer.style.display = 'block';
        props.referenceToContainer.style.height = 'auto';
        if(serviceClicked) {
            props.referenceToContainer.style.height = '700px';
        }

        referenceToBody.style.overflowY = 'auto'
        //props.referenceToContainer.style.height = '1500px'
        //props.closeStops(displayStopLink);
     }

     const removeStops = () => {
         localStorage.removeItem('savedStops')
         localStorage.removeItem('savedTrainStops')
         console.log('removing stops')
     }

     const generateColor = () => {
         if(savedStops.directionArrow === 'Northbound') {
             console.log('color will be green')
         }
     }

     const newStop = () => {
         props.newStop(false)
         stopReference.style.display = 'inline-block';
     }

     const deleteStop = (direction,e) => {
         console.log('deleting stop')
         console.log(e.target.parentElement.innerText);
         props.deleteStop(direction,e.target.parentElement.innerText)
         console.log(savedStops)
         console.log(savedStops.length)
         console.log(direction)
         if(savedStops.length === 0) {
            console.log('no more saved stops')
        }
        //prevents link click event from firing off from this event
        e.stopPropagation();
     }

     const stopLink = (e) => {
         let overlay = e.target.parentElement.parentElement.parentElement;
         overlay.style.width = '0';
         overlay.parentElement.parentElement.parentElement.style.overflowY = 'auto'
         let selectedStop = savedStops.find((stop) => {
            return stop.stopName === e.target.innerText.trim()
        })
        props.stopLink(selectedStop)
        referenceToBody.style.overflowY = 'auto'
     }

     const deleteTrainStop = (destination,e,background,index) => {
        let eventText;
        let eventTextLength;
        let stationString;
        if(destination === 'Forest Park') {
            console.log('Destination is Forest Park')
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }
        else if(destination === `O'Hare`) {
            console.log('Destination is O\'hare')
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 10)
        }
        else if(destination === `95th/Dan Ryan`) {
            console.log('95th/Dan Ryan')
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 16)
        }
        else if(destination === `Howard`) {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }
        else if(destination === 'Loop') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 8)
        }
        else if(destination === 'Kimball') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 11)
        }

        else if(destination === 'Howard') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Linden') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Harlem/Lake') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }

        else if(destination === 'Ashland/63rd') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 15)
        }

        else if(destination === '63rd Street') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }

        else if(destination === 'Cottage Grove') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 16)
        }

        else if(destination === '54th/Cermak') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }

        else if(destination === 'Loop') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 7)
        }

        else if(destination === 'Midway') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Loop') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 7)
        }

        else if(destination === 'Skokie') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Howard') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }


        else if(destination === 'See train') {
            eventText = e.target.parentElement.innerText;
            eventTextLength = e.target.parentElement.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 12)
        }
        console.log(stationString)

       //prevents link click event from firing off from this event
       props.deleteTrainStop(destination,stationString,background,index);
       e.stopPropagation();
     }

     const trainStopLink = (destination,e,background) => {
        let overlay = e.target.parentElement.parentElement.parentElement;
        overlay.style.width = '0';
        overlay.parentElement.parentElement.parentElement.style.overflowY = 'auto'
        let eventText;
        let eventTextLength;
        let stationString;
        if(destination === 'Forest Park') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }
        else if(destination === `O'Hare`) {
            console.log('Destination is O\'hare')
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 10)
        }
        else if(destination === `95th/Dan Ryan`) {
            console.log('95th/Dan Ryan')
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 16)
        }
        else if(destination === `Howard`) {
            console.log('Howard')
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Loop') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 8)
        }
        else if(destination === 'Kimball') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 11)
        }
        else if(destination === 'Howard') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Linden') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Harlem/Lake') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }

        else if(destination === 'Ashland/63rd') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 15)
        }

        else if(destination === '63rd Street') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }

        else if(destination === 'Cottage Grove') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 16)
        }

        else if(destination === '54th/Cermak') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 14)
        }

        else if(destination === 'Loop') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 7)
        }

        else if(destination === 'Midway') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Loop') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 7)
        }

        else if(destination === 'Skokie') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        else if(destination === 'Howard') {
            eventText = e.target.innerText;
            eventTextLength = e.target.innerText.length;
            stationString = eventText.slice(-eventTextLength,eventTextLength - 9)
        }

        let selectedStop = savedTrainStops.find((stop) => {
            //station with multiple lines use background to filter line
           return stop.stationName === stationString.trim() && stop.background === background && stop.destination === destination
         })
        props.trainStopLink(selectedStop);
        referenceToBody.style.overflowY = 'auto'

     }

     const aboutClicked = () => {
        props.aboutClicked();
        stopReference.style.display = 'none';
     }

     
     return (
         <nav>
             <ul style={inlineBlock}>
                 <li><Link onClick={newStop} to='/'>Chicago Transit</Link></li>
                 <li><Link onClick={aboutClicked} to='/about'>About</Link></li>
             </ul>
             {/* {openTabOne?<div id='stopsLink' onClick={openStops} style={tabStyle}>
                Stops
                 </div>
            :null} */}
            <div id='stopsLink' onClick={openStops} style={tabStyle}>Stops</div>
            <div className='overlay'>
                <div className='overlay-content'>
                    <div id='topOverlay'>
                        <div onClick={closeStops} id='closeIconContainer'>
                            <i className="fa fa-window-close" aria-hidden="true"></i>
                        </div>
                        <div id='titleContainer'>
                            <h1>Your Stops</h1>
                        </div>
                        <div id='divider'></div>
                        {/* <div id='removeStopsContainer'>
                            <div id='removeText'>
                                Remove All Stops
                            </div>
                            <div id='trashIcon' onClick={removeStops}>
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </div>
                        </div> */}
                    </div>
                    {/* {loading?<Spinner></Spinner>:<Fragment>
                    <ul id='savedBusList'>
                         {savedStops.map((stop,index) => (
                            <li onClick={stopLink} style={stopList} key={index}>{stop.stopName} 
                            <i id='directionStyle' style={{color: stop.directionColor}} className={stop.directionArrow} aria-hidden="true"></i>
                            <i onClick={(e) => deleteStop(stop.direction,e)} style={deleteStopStyle} className="fa fa-times" aria-hidden="true"></i>
                            </li>
                        ))}
                    </ul>
                    <ul id='savedTrainList'>
                        {savedTrainStops.map((stop,index) => (
                            <li onClick={(e) => trainStopLink(stop.destination,e,stop.background,index)} style={{backgroundColor: stop.background}} key={index}>{stop.stationName} to {stop.destination}
                                <i onClick={(e) => deleteTrainStop(stop.destination,e,stop.background,index)} style={deleteStopStyle} className="fa fa-times" aria-hidden="true"></i>
                            </li>
                        ))}
                        {savedTrainStops !== null? savedTrainStops.map((stop,index) => (
                            <li onClick={(e) => trainStopLink(stop.destination,e,stop.background,index)} style={{backgroundColor: stop.background}} key={index}>{stop.stationName} to {stop.destination}
                                <i onClick={(e) => deleteTrainStop(stop.destination,e,stop.background,index)} style={deleteStopStyle} className="fa fa-times" aria-hidden="true"></i>
                            </li>
                        )):null}
                    </ul></Fragment>}  */}
                    <ul id='savedBusList'>
                         {savedStops.map((stop,index) => (
                            <li onClick={stopLink} style={stopList} key={index}>{stop.stopName} 
                            <i id='directionStyle' style={{color: stop.directionColor}} className={stop.directionArrow} aria-hidden="true"></i>
                            <i onClick={(e) => deleteStop(stop.direction,e)} style={deleteStopStyle} className="fa fa-times" aria-hidden="true"></i>
                            </li>
                        ))}
                    </ul>
                    <ul id='savedTrainList'>
                        {/* {savedTrainStops.map((stop,index) => (
                            <li onClick={(e) => trainStopLink(stop.destination,e,stop.background,index)} style={{backgroundColor: stop.background}} key={index}>{stop.stationName} to {stop.destination}
                                <i onClick={(e) => deleteTrainStop(stop.destination,e,stop.background,index)} style={deleteStopStyle} className="fa fa-times" aria-hidden="true"></i>
                            </li>
                        ))} */}
                        {savedTrainStops !== null? savedTrainStops.map((stop,index) => (
                            <li onClick={(e) => trainStopLink(stop.destination,e,stop.background,index)} style={{backgroundColor: stop.background}} key={index}>{stop.stationName} to {stop.destination}
                                <i onClick={(e) => deleteTrainStop(stop.destination,e,stop.background,index)} style={deleteStopStyle} className="fa fa-times" aria-hidden="true"></i>
                            </li>
                        )):null}
                    </ul>   
                </div>
            </div>
         </nav>
     ) 
}


Navbar.propTypes = {
    snapShot: PropTypes.array.isRequired,
    chosenRoute: PropTypes.object.isRequired,
    openTabOne: PropTypes.bool.isRequired,
    savedStops: PropTypes.array.isRequired
}

const inlineBlock = {
    display:'inline-block',
}

const tabStyle = {
    display:'inline-block',
    color: '#fff',
    fontFamily: 'Archivo Black'  
}



const stopList = {
    display: 'block',
    fontSize: '1em',
    paddingTop: '14px'
}

const deleteStopStyle = {
    fontSize: '1.4em',
    paddingLeft: '6px'
}

const trainList = {
    display: 'block',
}


export default Navbar;