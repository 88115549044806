import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';




class TrainDestination extends Component {

    
   state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
      
   }

   chooseTrainDestination = (e) => {
        this.props.chooseTrainDestination(e.target.innerText)
   }
   
   render() {
    const {background,trainDirection } = this.props;
    return (
        <div id='trainDestinationContainer' style={{backgroundColor: background}}>
            <h2>Choose Destination</h2>
            {trainDirection.map((destination, index) => (
                <div onClick={this.chooseTrainDestination} className='trainDestinationItem' key={index}>{destination}</div>
            ))}
        </div>
    )

   }
}
export default TrainDestination;