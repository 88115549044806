import '../../App.css';
import React,{Component,Fragment} from 'react';
import RouteItem from './RouteItem';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';


class BusDirectionItem extends Component {

    
   state = {
      chosenDirection: ''
   }

   componentDidMount() {
       
   }

   static propTypes = {
       direction: PropTypes.object.isRequired
   }

   chooseDirection = (e) => {
      let direction;
      if(e.target.innerText === 'Northbound') 
         direction = 'Northbound'

      else if(e.target.innerText === 'Southbound') 
         direction = 'Southbound'
      

      else if(e.target.innerText === 'Eastbound') 
         direction = 'Eastbound'
      

      else 
         direction = 'Westbound'
      

      this.setState({chosenDirection: direction}, function() {
         this.props.chooseDirection(this.state.chosenDirection)
      })
   }

  

   render() {
      const {direction} = this.props;

      return (
         <div onClick={this.chooseDirection}  style={{fontFamily:'Raleway',textAlign: 'center', fontSize:'1.3em', marginBottom: '6px', color:'#333'}}>
             {direction.dir}
         </div>
      )

   }
}



export default BusDirectionItem;
