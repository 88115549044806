import './App.css';
import React,{Component, Fragment} from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Switch, Route,Redirect} from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import About from './components/pages/About';
import BusRoutes from './components/routes/BusRoutes';
import BusDirections from './components/routes/BusDirections';
import BusStops from './components/routes/BusStops'
import BusArrivals from './components/routes/BusArrivals';
import NoService from './components/routes/NoService';
import TrainLines from './components/routes/TrainLines';
import TrainStops from './components/routes/TrainStops';
import TrainDestination from './components/routes/TrainDestination';
import TrainArrivals from './components/routes/TrainArrivals';
import ServiceBulletin from './components/routes/ServiceBulletin';




class App extends Component {

  state = {
    root :'',
    body: '',
    busRoutes: [],
    loading: false,
    revealBusRoutes: false,
    badgeContainer: false,
    busBadge: false,
    trainBadge: false,
    chosenRoute: {},
    revealBusDirections: false,
    chosenDirections: [],
    ctaBusStops: [],
    chosenStop: {},
    userDirection: '',
    revealBusStops: false,
    busBackground: '',
    stopId: '',
    arrivingBuses: null,
    revealBusArrivals: false,
    routeCounter: 0,
    snapShot: [],
    openTabOne: false,
    revealNoService: false,
    serviceMessage: ' ',
    savedRoutes: [],
    savedStops: [],
    revealTrainArrivals: false,
    revealTrainStops: false,
    chosenTrainLine: '',
    trainStops: [],
    trainStopsBackground: '',
    trainArrivals: [],
    trainDestination: '',
    revealTrainDestination: false,
    userTrainDestination: [],
    trainArrivalTimes: [],
    revealTrainArrivalTimes: false,
    stationName: '',
    trainStationNumber: '',
    chosenDestination: '',
    ohareRosemont: '',
    rosemontNexusArray: [],
    savedTrainStops: [],
    noUpcomingTime: '',
    revealAlerts: false,
    closeAlertDropDown: false,
    serviceClicked: false,
    busCoordinates: null
  }

  containerRef = React.createRef();
  serviceRef = React.createRef();
  

  blueLine = [
    {station: {name: 'O\'hare', stationNumber: '40890'}},
    {station: {name: 'Rosemont', stationNumber: '40820'}},
    {station: {name: 'Cumberland', stationNumber: '40230'}},
    {station: {name: 'Harlem(O\'hare)', stationNumber: '40750'}},
    {station: {name: 'Jefferson Park', stationNumber: '41280'}},
    {station: {name: 'Montrose', stationNumber: '41330'}},
    {station: {name: 'Irving Park', stationNumber: '40550'}},
    {station: {name: 'Addison', stationNumber: '41240'}},
    {station: {name: 'Belmont', stationNumber: '40060'}},
    {station: {name: 'Logan Square', stationNumber: '41020'}},
    {station: {name: 'California', stationNumber: '40570'}},
    {station: {name: 'Western(O\'hare)', stationNumber: '40670'}},
    {station: {name: 'Damen', stationNumber: '40590'}},
    {station: {name: 'Division', stationNumber: '40320'}},
    {station: {name: 'Chicago', stationNumber: '41410'}},
    {station: {name: 'Grand', stationNumber: '40490'}},
    {station: {name: 'Clark/Lake', stationNumber: '40380'}},
    {station: {name: 'Washington', stationNumber: '40370'}},
    {station: {name: 'Monroe', stationNumber: '40790'}},
    {station: {name: 'Jackson', stationNumber: '40070'}},
    {station: {name: 'Lasalle', stationNumber: '41340'}},
    {station: {name: 'Clinton', stationNumber: '40430'}},
    {station: {name: 'UIC-Halsted', stationNumber: '40350'}},
    {station: {name: 'Racine', stationNumber: '40470'}},
    {station: {name: 'Illinois Medical District', stationNumber: '40810'}},
    {station: {name: 'Western(Forest Park)', stationNumber: '40220'}},
    {station: {name: 'Kedzie-Homan', stationNumber: '40250'}},
    {station: {name: 'Pulaski', stationNumber: '40920'}},
    {station: {name: 'Cicero', stationNumber: '40970'}},
    {station: {name: 'Austin', stationNumber: '40010'}},
    {station: {name: 'Oak Park', stationNumber: '40180'}},
    {station: {name: 'Harlem(Forest Park)', stationNumber: '40980'}},
    {station: {name: 'Forest Park', stationNumber: '40390'}},
    
  ];

  redLine = [
    {station: {name: 'Howard', stationNumber: '40900'}},
    {station: {name: 'Jarvis', stationNumber: '41190'}},
    {station: {name: 'Morse', stationNumber: '40100'}},
    {station: {name: 'Loyola', stationNumber: '41300'}},
    {station: {name: 'Granville', stationNumber: '40760'}},
    {station: {name: 'Thorndale', stationNumber: '40880'}},
    {station: {name: 'Bryn Mawr', stationNumber: '41380'}},
    // {station: {name: 'Berwyn', stationNumber: '40340'}},
    {station: {name: 'Argyle', stationNumber: '41200'}},
    // {station: {name: 'Lawrence', stationNumber: '40770'}},
    {station: {name: 'Wilson', stationNumber: '40540'}},
    {station: {name: 'Sheridan', stationNumber: '40800'}},
    {station: {name: 'Addison', stationNumber: '41420'}},
    {station: {name: 'Belmont', stationNumber: '41320'}},
    {station: {name: 'Fullerton', stationNumber: '41220'}},
    {station: {name: 'North/Clybourn', stationNumber: '40650'}},
    {station: {name: 'Clark/Division', stationNumber: '40630'}},
    {station: {name: 'Chicago', stationNumber: '41450'}},
    {station: {name: 'Grand', stationNumber: '40330'}},
    {station: {name: 'Lake', stationNumber: '41660'}},
    {station: {name: 'Monroe', stationNumber: '41090'}},
    {station: {name: 'Jackson', stationNumber: '40560'}},
    {station: {name: 'Harrison', stationNumber: '41490'}},
    {station: {name: 'Roosevelt', stationNumber: '41400'}},
    {station: {name: 'Cermak-Chinatown', stationNumber: '41000'}},
    {station: {name: 'Sox-35th', stationNumber: '40190'}},
    {station: {name: '47th', stationNumber: '41230'}},
    {station: {name: 'Garfield', stationNumber: '41170'}},
    {station: {name: '63rd', stationNumber: '40910'}},
    {station: {name: '69th', stationNumber: '40990'}},
    {station: {name: '79th', stationNumber: '40240'}},
    {station: {name: '87th', stationNumber: '41430'}},
    {station: {name: '95th/DanRyan', stationNumber: '40450'}},

  ]


  brownline = [
    {station: {name: 'Kimball', stationNumber: '41290'}},
    {station: {name: 'Kedzie', stationNumber: '41180'}},
    {station: {name: 'Francisco', stationNumber: '40870'}},
    {station: {name: 'Rockwell', stationNumber: '41010'}},
    {station: {name: 'Western', stationNumber: '41480'}},
    {station: {name: 'Damen', stationNumber: '40090'}},
    {station: {name: 'Montrose', stationNumber: '41500'}},
    {station: {name: 'Irving Park', stationNumber: '41460'}},
    {station: {name: 'Addison', stationNumber: '41440'}},
    {station: {name: 'Paulina', stationNumber: '41310'}},
    {station: {name: 'Southport', stationNumber: '40360'}},
    {station: {name: 'Kimball', stationNumber: '41290'}},
    {station: {name: 'Belmont', stationNumber: '41320'}},
    {station: {name: 'Wellington', stationNumber: '41210'}},
    {station: {name: 'Diversey', stationNumber: '40530'}},
    {station: {name: 'Fullerton', stationNumber: '41220'}},
    {station: {name: 'Armitage', stationNumber: '40660'}},
    {station: {name: 'Sedgwick', stationNumber: '40800'}},
    {station: {name: 'Chicago', stationNumber: '40710'}},
    {station: {name: 'Clark/Lake', stationNumber: '40380'}},
    {station: {name: 'State/Lake', stationNumber: '40260'}},
    {station: {name: 'Washington/Wabash', stationNumber: '41700'}},
    {station: {name: 'Adams/Wabash', stationNumber: '40680'}},
    {station: {name: 'Harold Washington Library', stationNumber: '40850'}},
    {station: {name: 'Lasalle/Van Buren', stationNumber: '40160'}},
    {station: {name: 'Quincy/Wells', stationNumber: '40040'}},
    {station: {name: 'Washington/Wells', stationNumber: '40730'}},
    {station: {name: 'Merchandise Mart', stationNumber: '40460'}},
  ]

  purpleLine = [
    {station: {name: 'Linden', stationNumber: '41050'}},
    {station: {name: 'Central', stationNumber: '41250'}},
    {station: {name: 'Noyes', stationNumber: '40400'}},
    {station: {name: 'Foster', stationNumber: '40520'}},
    {station: {name: 'Davis', stationNumber: '40050'}},
    {station: {name: 'Dempster', stationNumber: '40690'}},
    {station: {name: 'Main', stationNumber: '40270'}},
    {station: {name: 'South Boulevard', stationNumber: '40840'}},
    {station: {name: 'Howard', stationNumber: '40900'}},
    {station: {name: 'Wilson', stationNumber: '40540'}},
    {station: {name: 'Belmont', stationNumber: '41320'}},
    {station: {name: 'Wellington', stationNumber: '41210'}},
    {station: {name: 'Diversey', stationNumber: '40530'}},
    {station: {name: 'Fullerton', stationNumber: '41220'}},
    {station: {name: 'Armitage', stationNumber: '40660'}},
    {station: {name: 'Sedgwick', stationNumber: '40800'}},
    {station: {name: 'Chicago', stationNumber: '40710'}},
    {station: {name: 'Clark/Lake', stationNumber: '40380'}},
    {station: {name: 'State/Lake', stationNumber: '40260'}},
    {station: {name: 'Washington/Wabash', stationNumber: '41700'}},
    {station: {name: 'Adams/Wabash', stationNumber: '40680'}},
    {station: {name: 'Harold Washington Library', stationNumber: '40850'}},
    {station: {name: 'Lasalle/Van Buren', stationNumber: '40160'}},
    {station: {name: 'Quincy/Wells', stationNumber: '40040'}},
    {station: {name: 'Washington/Wells', stationNumber: '40730'}},
    {station: {name: 'Merchandise Mart', stationNumber: '40460'}},
  ]

  greenLine = [
    {station: {name: 'Harlem/Lake', stationNumber: '40020'}},
    {station: {name: 'Oak Park', stationNumber: '41350'}},
    {station: {name: 'Ridgeland', stationNumber: '40610'}},
    {station: {name: 'Austin', stationNumber: '41260'}},
    {station: {name: 'Central', stationNumber: '40280'}},
    {station: {name: 'Laramie', stationNumber: '40700'}},
    {station: {name: 'Cicero', stationNumber: '40480'}},
    {station: {name: 'Pulaski', stationNumber: '40030'}},
    {station: {name: 'Conservatory-Central Park Drive', stationNumber: '41670'}},
    {station: {name: 'Kedzie', stationNumber: '41070'}},
    {station: {name: 'California', stationNumber: '41360'}},
    {station: {name: 'Ashland', stationNumber: '40170'}},
    {station: {name: 'Morgan', stationNumber: '41510'}},
    {station: {name: 'Clinton', stationNumber: '41160'}},
    {station: {name: 'Clark/Lake', stationNumber: '40380'}},
    {station: {name: 'State/Lake', stationNumber: '40260'}},
    {station: {name: 'Washington/Wabash', stationNumber: '41700'}},
    {station: {name: 'Adams/Wabash', stationNumber: '40680'}},
    {station: {name: 'Roosevelt', stationNumber: '41400'}},
    {station: {name: 'Cermak-McCormick Place', stationNumber: '41690'}},
    {station: {name: '35th-Bronzevill-IIT', stationNumber: '41120'}},
    {station: {name: 'Indiana', stationNumber: '40300'}},
    {station: {name: '43rd', stationNumber: '41270'}},
    {station: {name: '47th', stationNumber: '41080'}},
    {station: {name: '51st', stationNumber: '40130'}},
    {station: {name: 'Garfield', stationNumber: '40510'}},
    {station: {name: 'Halsted', stationNumber: '40940'}},
    {station: {name: 'Ashland/63rd', stationNumber: '40290'}},
    {station: {name: 'King Drive', stationNumber: '41140'}},
    {station: {name: 'Cottage Grove', stationNumber: '40720'}},
  ]

  pinkLine = [
    {station: {name: '54th/Cermak', stationNumber: '40580'}},
    {station: {name: 'Cicero', stationNumber: '40420'}},
    {station: {name: 'Kostner', stationNumber: '40600'}},
    {station: {name: 'Pulaski', stationNumber: '40720'}},
    {station: {name: 'Central Park', stationNumber: '40780'}},
    {station: {name: 'Kedzie', stationNumber: '41040'}},
    {station: {name: 'California', stationNumber: '40440'}},
    {station: {name: 'Western', stationNumber: '40740'}},
    {station: {name: 'Damen', stationNumber: '40210'}},
    {station: {name: '18th', stationNumber: '40830'}},
    {station: {name: 'Polk', stationNumber: '41030'}},
    {station: {name: 'Ashland', stationNumber: '40170'}},
    {station: {name: 'Morgan', stationNumber: '41510'}},
    {station: {name: 'Clinton', stationNumber: '41160'}},
    {station: {name: 'Clark/Lake', stationNumber: '40380'}},
    {station: {name: 'State/Lake', stationNumber: '40260'}},
    {station: {name: 'Washington/Wabash', stationNumber: '41700'}},
    {station: {name: 'Adams/Wabash', stationNumber: '40680'}},
    {station: {name: 'Harold Washington Library', stationNumber: '40850'}},
    {station: {name: 'Lasalle/Van Buren', stationNumber: '40160'}},
    {station: {name: 'Quincy/Wells', stationNumber: '40040'}},
    {station: {name: 'Washington/Wells', stationNumber: '40730'}},
  ]

  orangeLine = [
    {station: {name: 'Midway', stationNumber: '40930'}},
    {station: {name: 'Pulaski', stationNumber: '40960'}},
    {station: {name: 'Kedzie', stationNumber: '41150'}},
    {station: {name: 'Western', stationNumber: '40310'}},
    {station: {name: '35th/Archer', stationNumber: '40120'}},
    {station: {name: 'Ashland', stationNumber: '41060'}},
    {station: {name: 'Halsted', stationNumber: '41130'}},
    {station: {name: 'Roosevelt', stationNumber: '41400'}},
    {station: {name: 'Harold Washington Library', stationNumber: '40850'}},
    {station: {name: 'Lasalle/Van Buren', stationNumber: '40160'}},
    {station: {name: 'Quincy/Wells', stationNumber: '40040'}},
    {station: {name: 'Washington/Wells', stationNumber: '40730'}},
    {station: {name: 'Clark/Lake', stationNumber: '40380'}},
    {station: {name: 'State/Lake', stationNumber: '40260'}},
    {station: {name: 'Washington/Wabash', stationNumber: '41700'}},
    {station: {name: 'Adams/Wabash', stationNumber: '40680'}},
  ]

  yellowLine = [
    {station: {name: 'Dempster-Skokie', stationNumber: '40140'}},
    {station: {name: 'Oakton-Skokie', stationNumber: '41680'}},
    {station: {name: 'Howard', stationNumber: '40900'}},
  ]

  


  async componentDidMount() {
    //const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getroutes?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&format=json`);
    //this.setState({busRoutes:res.data['bustime-response'].routes,loading: false})
    //this.setState({busRoutes:res.data['bustime-response'].routes})
    let data = JSON.parse(localStorage.getItem('savedStops'));
    console.log(data)
    let trainData = JSON.parse(localStorage.getItem('savedTrainStops'));
    let container = document.getElementsByClassName('container');
    //container[0].parentElement.style.background = 'red';
    this.setState({root: container[0].parentElement});
    this.setState({body: container[0].parentElement.parentElement});
    this.setState({openTabOne:true})
    if(data !== null) {
      console.log('contains data')
      this.setState({loading:true})
      //STOPS EXIST IN DATABASE SO ALLOW STOPS LINK TO DISPLAY
      //this.setState({savedStops: data})
      this.setState({savedStops: data}, () => {
        this.setState({savedStops: data})
        this.setState({loading:false})
      })
      

      //this.setState({openTabOne: true})
      // this.setState({openTabOne: true}, () => {
      //   this.setState({openTabOne:true})
      //   console.log('firing off')
      // })
      //this.setState({loading:false})
    }

    else {
      console.log('empty')
      // this.setState({loading:true})
      //this.setState({openTabOne: false})
      // this.setState({openTabOne: false}, () => {
      //   this.setState({openTabOne: false})
      // })
    }

    if(this.state.savedStops.length === 0) {
      //this.setState({openTabOne: false})
      // this.setState({openTabOne: false}, () => {
      //   this.setState({openTabOne: false})
      // })
    }

    //TRAIN DATABASE
    if(trainData !== null || data !== null) {
      this.setState({loading:true})
      console.log('stops exist')
      //STOPS EXIST IN DATABASE SO ALLOW STOPS LINK TO DISPLAY
      // this.setState({openTabOne: true},() => {
      //   this.setState({openTabOne: true})
      // })
      //this.setState({openTabOne: true})
      this.setState({savedTrainStops: trainData})
      this.setState({loading:false})
      // this.setState({savedTrainStops: trainData})
      // this.setState({loading:false})
      

      // this.setState({savedTrainStops: trainData}, () => {
      //   this.setState({savedTrainStops: trainData})
      // })
    }

    else {
      //this.setState({loading:true})
    }

    // if(this.state.savedTrainStops.length === 0 && this.state.savedStops.length === 0) {
    //   this.setState({openTabOne: false})
    //   this.setState({openTabOne: false}, () => {
    //     this.setState({openTabOne: false})
    //   })
    // }
  }


  //TRACK WHEN NAV STOPS LINK IS CLICKED

  openStops = (msg) => {
    this.closeAlertProp()
  }

  closeAlertProp = () => {
    this.setState({closeAlertDropDown: true})
  }

  revealBusRoutes = async(e) => {
    this.setState({revealBusRoutes: true})
    // this.setState({busBadge: true})
    // this.setState({trainBadge: true})
    this.setState({badgeContainer: true})
    this.setState({loading : true})
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getroutes?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&format=json`);
    this.setState({busRoutes:res.data['bustime-response'].routes})
    this.setState({loading:false})
  }

  clickBusRoute = (route) => {
    console.log(route)
    this.setState({chosenRoute:route}, () => {
      this.getDirections(route) 
      this.setState({chosenRoute: route})
    })
    this.setState({revealBusRoutes: false})
    this.setState({revealBusDirections: true})
  }

  getDirections = async (route) => {
    this.setState({loading : true})
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getdirections?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&rt=${route.rt}&format=json`)
    let convert = res.data;
    let directions = convert['bustime-response']['directions'];
    this.setState({chosenDirections: directions}) 
    this.setState({loading : false})
    this.setState({busBackground: '#fff'});
  }

  chooseDirection = (direction) => {
    this.setState({userDirection: direction})
    this.getStops(direction); 
  }

  getStops = async (direction) => {
    this.setState({revealBusDirections: false})
    this.setState({revealBusStops: true})
    this.setState({loading : true})
    const uriEncodedDirection = encodeURIComponent(direction);
    let route = this.state.chosenRoute.rt
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getstops?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&rt=${route}&dir=${uriEncodedDirection}&format=json`);
    let convert = res.data;
    let stops = convert['bustime-response']['stops']
    this.setState(null, () => {
      this.setState({ctaBusStops: stops})
    })
    this.setState({loading: false})
  }

  chooseStop = (stop,stopName) => {
    this.setState({stopId: stop}, () => {
      this.getTimes(stop)
    })
    this.setState({chosenStop: stopName})
  }

  getTimes = async (stop) => {
    let routesFromStorage = localStorage.getItem('savedRoutes');
    let stopsFromStorage = localStorage.getItem('savedStops');
    let arrivalTimes;
    let savedRoutes;
    let savedStops;
    if(routesFromStorage === null) {
      savedRoutes = [];
    }
    else {
      savedRoutes = JSON.parse(routesFromStorage);
    }

    if(stopsFromStorage === null) {
      savedStops = [];
    }
    else {
      savedStops = JSON.parse(stopsFromStorage);
    }
   
    this.setState({revealBusStops: false})
    this.setState({revealBusArrivals: true})
    this.setState({loading : true})
    const uriEncodedStop = encodeURIComponent(stop)
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getpredictions?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&stpid=${stop}&top=3&format=json`);
    let convert = res.data;
    if(convert['bustime-response'].hasOwnProperty('error')) {
      console.log('contains error')
      console.log(convert['bustime-response']['error'][0])
      if(convert['bustime-response']['error'][0].msg === 'No service scheduled') {
        this.setState(null,() => {
          this.setState({revealNoService: true});
        })
        console.log(convert['bustime-response']['error'][0].msg)
        this.setState({loading : false})
        //this.setState({revealNoService: true});
        // this.setState(null,() => {
        //   this.setState({revealNoService: true});
        // })
        this.setState({serviceMessage:convert['bustime-response']['error'][0].msg});
      }

      else if (convert['bustime-response']['error'][0].msg === 'No arrival times') {
        this.setState(null,() => {
          this.setState({revealNoService: true});
        })
        this.setState({serviceMessage:convert['bustime-response']['error'][0].msg});
        //this.setState({revealNoService: true});
        // this.setState(null,() => {
        //   this.setState({revealNoService: true});
        // })
        this.setState({loading : false})
      }
    }

    else {
      console.log('still populating arrival time after no sevice! ')
      arrivalTimes = convert['bustime-response']['prd']
      this.setState(null, () => {
        this.setState({arrivingBuses: arrivalTimes});
      })
      this.setState({loading : false})
      this.realTimeSnapshot(uriEncodedStop)  
      //STOP
      let userStop = {};
      userStop.stopName = this.state.chosenStop;
      userStop.stopId = stop;
      userStop.direction = this.state.userDirection;
      let directionArrow;
      let directionColor;


      if(userStop.direction=== 'Northbound') {
          directionArrow = 'fa fa-arrow-circle-up';
          directionColor = 'rgb(15, 219, 76)'
      }
 
      else if(userStop.direction === 'Southbound') {
         directionArrow = 'fa fa-arrow-circle-down';
         directionColor = 'rgb(155, 25, 25)'

      }
 
      else if(userStop.direction === 'Westbound') {
         directionArrow = 'fa fa-arrow-circle-left';
         directionColor = 'rgb(212, 226, 13)'
      }
 
      else {
         directionArrow = 'fa fa-arrow-circle-right';
         directionColor = 'rgb( 226, 13, 120)'
      }
      userStop.directionArrow = directionArrow;
      userStop.directionColor = directionColor;
      //IF STOP ALREADY EXISTS IN DATABASE DO NOT ALLOW TO SAVE
      let checkMatch = savedStops.some(stop => stop.stopName === userStop.stopName && stop.direction === userStop.direction)
      if(checkMatch !== true) {
        savedStops.push(userStop)
      }
      //savedStops.push(userStop);
      //GETTING COORDINATES
     this.getCoordinates(arrivalTimes);
      localStorage.setItem('savedStops', JSON.stringify(savedStops));
      this.setState({savedStops: savedStops})
    }

     //GETTING COORDINATES
     //this.getCoordinates(arrivalTimes);
  }

  getCoordinates = async (times) => {
    console.log(times)
    let busIdArray = [];
    let createdObjects = [];
    for(let i = 0; i < times.length; i++) {
      busIdArray.push(times[i].vid);
    }
    for(let i = 0; i < busIdArray.length; i++) {
      createdObjects[i] = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getvehicles?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&vid=${busIdArray[i]}&format=json`);
    }
    let captured = Promise.all(createdObjects);
    console.log(captured);
    let checkForError = false;
    let filteredResult = [];
    let intermediary = null;
    let location = null;
    captured.then((result) => {
      console.log(result)
      if(result !== null) {
        for(let i = 0; i < result.length; i++) {
          console.log(result[i].data['bustime-response']['vehicle'][0]);
          console.log(result[i].data.hasOwnProperty('error'));
          if(result[i].data.hasOwnProperty('error')) {
            checkForError = true;
            location = i;
          }
        }

        if(checkForError) {
          intermediary = result.filter((item,index) => {
            return result !== index
          })
        }

        filteredResult = intermediary;
        console.log(filteredResult)
        
        this.setState({busCoordinates:result})
      }
      
    })
    
  }

  

  realTimeSnapshot = async (stop) => {
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getpredictions?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&stpid=${stop}&top=5&format=json`);
    let convert = res.data;
    this.setState({openTabOne: true})
    this.setState({routeCounter: this.state.routeCounter+=1})
    let arrivalTimes = convert['bustime-response']['prd']
    this.setState(null, () => {
      this.setState({snapShot: arrivalTimes})
    })
  }


  refreshTime = async () => {
    this.setState({loading : true})
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getpredictions?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&stpid=${this.state.stopId}&top=3&format=json`);
    let convert = res.data;
    if(convert['bustime-response'].hasOwnProperty('error')) {
      console.log(convert['bustime-response']['error'][0])
      if(convert['bustime-response']['error'][0].msg === 'No service scheduled') {
        console.log(convert['bustime-response']['error'][0].msg)
        this.setState({loading : false})
        this.setState({revealNoService: true});
        this.setState({serviceMessage:convert['bustime-response']['error'][0].msg});
      }

      else if (convert['bustime-response']['error'][0].msg === 'No arrival times') {
        this.setState({serviceMessage:convert['bustime-response']['error'][0].msg});
        this.setState({revealNoService: true});
        this.setState({loading : false})
      }
    }

    else {
      let arrivalTimes = convert['bustime-response']['prd']
      this.setState(null, () => {
        this.setState({arrivingBuses: arrivalTimes});
      })
       //GETTING COORDINATES
      this.getCoordinates(arrivalTimes);
      this.setState({loading : false})
    }
  }

  newStop = async (show) => {
    this.setState({openTabOne: true})
    this.setState({revealBusArrivals: show})
    this.setState({revealBusRoutes: show})
    this.setState({revealBusDirections: show})
    this.setState({badgeContainer: false})
    this.setState({revealNoService: false})
    this.setState({revealBusStops: false})
    this.setState({revealTrainArrivals: false})
    this.setState({revealTrainStops: false})
    this.setState({revealTrainDestination: false})
    this.setState({revealTrainArrivalTimes: false})
    this.setState({revealAlerts: false})
    this.setState({serviceClicked:false})
    this.containerRef.current.style.height='auto';

    //GETTING ALL STOPS AGAIN
    this.setState({loading : true})
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getroutes?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&format=json`);
    this.setState({busRoutes:res.data['bustime-response'].routes,loading: false})
    // if(this.state.savedStops.length === 0 && this.state.savedTrainStops.length === 0) {
    //   this.setState({openTabOne: false})
    // }

    // else {
    //   this.setState({openTabOne: true})
    // }
  }

  removeStops = (message) => {
    //set open tab one state to false and set saved stops in state to empty array
    this.setState({savedStops: []})
    this.setState({badgeContainer: false})
    this.setState({arrivingBuses: []});
    this.setState({revealBusArrivals: false});
    this.setState({revealBusArrivals: false});
    this.setState({revealTrainStops: false})
    this.setState({revealTrainDestination: false})
    this.setState({revealTrainArrivalTimes: false})
    this.setState({savedTrainStops: []})
    this.setState({openTabOne: false})
    
  }

  deleteStop = (direction,stop) => {
    console.log(stop)
    console.log(direction)
    let isolateMatch = this.state.savedStops.filter((filtered) => {
      return filtered.stopName === stop
    })
    console.log(isolateMatch);
    let filterDirection = isolateMatch.filter((filtered) => {
      return filtered.direction === direction
    })
    console.log(filterDirection[0])
    let filterStops = this.state.savedStops.filter((filtered) => {
      return filtered.stopId !== filterDirection[0].stopId
    })
    // let testFilter = this.state.savedStops.some((filter) => {
    //   return filter.stopName !== filterDirection[0].stopName && filter.direction !== filterDirection[0].direction
    // })
    console.log(filterStops)
    //console.log(testFilter)
    this.setState({savedStops: filterStops});
    let dataBaseStops = JSON.parse(localStorage.getItem('savedStops'));
    console.log(dataBaseStops)

    let filterDataBase = dataBaseStops.filter((filtered) => {
        console.log(filtered)
        return filtered.stopName !== stop.trim() && filtered.direction !== stop.direction
    })
    console.log(filterDataBase);
    localStorage.setItem('savedStops', JSON.stringify(filterDataBase))
    if(this.state.savedStops.length === 0) {
      console.log('nothin in array')
      //this.setState({openTabOne: false})
    } 
  }

  closeStops = (link) => {
    console.log(link)
    this.setState({openTabOne: link})
  }

  stopLink = async (stop) => {
    this.setState({badgeContainer: true})
    this.setState({loading:true})
    this.setState({chosenStop: stop.stopName})
    this.setState({revealBusArrivals: false})
    this.setState({revealBusArrivals: true})
    this.setState({revealBusRoutes: false})
    this.setState({revealBusDirections: false})
    this.setState({revealBusDirections: false})
    this.setState({revealTrainArrivalTimes: false})
    this.setState({revealTrainDestination: false})
    this.setState({revealTrainArrivals: false})
    this.setState({revealAlerts: false})
    this.setState({stopId : stop.stopId})
    let arrivalTimes;
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://www.ctabustracker.com/bustime/api/v2/getpredictions?key=${process.env.REACT_APP_BUS_TRACKER_KEY}&stpid=${stop.stopId}&top=3&format=json`);
    let convert = res.data;
    //turn badge container true to hide homepage
    if(convert['bustime-response'].hasOwnProperty('error')) {
      console.log('contains error')
      console.log(convert['bustime-response']['error'][0])
      if(convert['bustime-response']['error'][0].msg === 'No service scheduled') {
        //arrivalTimes = convert['bustime-response']['error'][0].msg
        console.log(convert['bustime-response']['error'][0].msg)
        this.setState({loading : false})
        this.setState({revealNoService: true});
        this.setState({serviceMessage:convert['bustime-response']['error'][0].msg});
        this.setState({revealBusArrivals: false})
      }

      else if (convert['bustime-response']['error'][0].msg === 'No arrival times') {
        console.log('no arrivals')
        //arrivalTimes = convert['bustime-response']['error'][0].msg
        this.setState({serviceMessage:convert['bustime-response']['error'][0].msg});
        this.setState({revealNoService: true});
        this.setState({loading : false})
        this.setState({revealBusArrivals: false})
      }
    }

    else {
      arrivalTimes = convert['bustime-response']['prd']
      console.log(arrivalTimes)
      this.setState(null, () => {
        this.setState({arrivingBuses: arrivalTimes});
        this.setState({revealNoService: false});

        //this.setState({revealBusArrivals: true})
         //GETTING COORDINATES
         this.getCoordinates(arrivalTimes);
      })
      this.setState({loading : false})
    }
  }

  ///TRAIN CODE///////////////////////////////////////////////////////

  revealTrain = async () => {
    this.setState({badgeContainer: true})
    this.setState({revealTrainArrivals: true})
  }

  chooseLine = async (line) => {
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://lapi.transitchicago.com/api/1.0/ttpositions.aspx?key=${process.env.REACT_APP_TRAIN_TRACKER_KEY}&rt=${line}&outputType=json`);
    let convert = res.data;
    this.setState({revealTrainArrivals: false})
    this.setState({revealTrainStops: true})
    this.setState({chosenTrainLine : line})
    if(line === 'Blue') {
      this.setState({trainStops: this.blueLine})
      this.setState({trainStopsBackground: 'blue'})
    }
    else if(line === 'Red') {
      this.setState({trainStops: this.redLine})
      this.setState({trainStopsBackground: 'red'})
    }
    else if(line === 'Brn') {
      this.setState({trainStops: this.brownline})
      this.setState({trainStopsBackground: 'brown'})
    }
    else if(line === 'P') {
      this.setState({trainStops: this.purpleLine})
      this.setState({trainStopsBackground: 'purple'})
    }

    else if(line === 'G') {
      this.setState({trainStops: this.greenLine})
      this.setState({trainStopsBackground: 'green'})
    }

    else if(line === 'Pink') {
      this.setState({trainStops: this.pinkLine})
      this.setState({trainStopsBackground: '#e75480'})
    }

    else if(line === 'Org') {
      this.setState({trainStops: this.orangeLine})
      this.setState({trainStopsBackground: 'orange'})
    }

    else if(line === 'Y') {
      this.setState({trainStops: this.yellowLine})
      this.setState({trainStopsBackground: 'yellow'})
    }
  }

  chooseTrainStop = async (station,name) => {
    let trainLine = this.state.chosenTrainLine;
    let filteredTrainArray;
    this.setState({loading : true})
    console.log(station)
    //destinations to omit
    let Linden = 'Linden';
    let Skokie = 'Skokie';
    let Ashland63 = 'Ashland/63rd';
    let HarlemLake = 'Harlem/Lake';
    let Midway = 'Midway';
    let Loop = 'Loop';
    let CottageGrove = 'Cottage Grove';
    let SeeTrain = 'See train';
    let Kimball = 'Kimball';
    let ninetyFifthDanRyan = '95th/Dan Ryan';
    let Howard = 'Howard';
    let fiftyFourthCermak = '54th/Cermak';
    let forestPark = 'Forest Park';
    let ohare = `O'Hare`;

    this.setState({trainStationNumber: station})
    this.setState({revealTrainStops: false})
    this.setState({revealTrainDestination: true})

    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://lapi.transitchicago.com/api/1.0/ttarrivals.aspx?key=${process.env.REACT_APP_TRAIN_TRACKER_KEY}&max=15&mapid=${station}&outputType=json`);
    let convert = res.data;
    console.log(res.data)
    let destinationArray = [];
    let arrivalTimes = convert['ctatt']['eta'];
    if(arrivalTimes === undefined) {
      console.log('no arrival times')
    }
    for(let i = 0; i < arrivalTimes.length; i++) {
      destinationArray.push(arrivalTimes[i].destNm);
    }

    //SET: EXTRACT ONLY UNIQUE ELEMENTS
    let uniqueElements = [...new Set(destinationArray)];
    let trainArray = uniqueElements.sort();
    
    //BLUE LINE
    if(trainLine === 'Blue') {
      filteredTrainArray = trainArray.filter((extractor) => {
        return extractor !== HarlemLake && extractor !== Midway  && extractor !== Kimball  && extractor !== fiftyFourthCermak
        && extractor !== CottageGrove  && extractor !== Ashland63 && extractor !== Linden
      })
      this.setState({userTrainDestination: filteredTrainArray})
    }
     //RED LINE REMOVE OVERLAPPING DESTINATIONS
    else if(trainLine === 'Red') {
      filteredTrainArray = trainArray.filter((extractor) => {
      return extractor !== Linden && 
      extractor !== Skokie && extractor !== Ashland63 && extractor !== HarlemLake && 
      extractor !== Midway && extractor !== Loop && extractor !== CottageGrove && extractor !== SeeTrain
      && extractor !== Kimball
      })
      this.setState({userTrainDestination: filteredTrainArray})
    }

    else if(trainLine === 'Brn') {
      filteredTrainArray = trainArray.filter((extractor) => {
        return extractor !== ninetyFifthDanRyan && extractor !== Howard && extractor !== Linden
        && extractor !== fiftyFourthCermak && extractor !== CottageGrove && extractor !== forestPark
        && extractor !== HarlemLake && extractor !== Midway && extractor !== ohare && extractor !== Ashland63
        })
        if(filteredTrainArray.length === 0) {
          console.log('no upcoming times')
          this.setState({noUpcomingTime: 'No upcoming times!'})
        }
        else {
          this.setState({userTrainDestination: filteredTrainArray})
        }
    }

    else if(trainLine === 'P') {
      console.log('purple line chosen')
      filteredTrainArray = trainArray.filter((extractor) => {
        return extractor !== ninetyFifthDanRyan && extractor !== Skokie && extractor !== Kimball && extractor !== Loop
        && extractor !== HarlemLake && extractor !== CottageGrove && extractor !== Midway && extractor !== HarlemLake
        && extractor !== fiftyFourthCermak && extractor !== ohare && extractor !== forestPark && extractor !== Ashland63
        })
        if(filteredTrainArray.length === 0) {
          console.log('no upcoming times')
          this.setState({noUpcomingTime: 'No upcoming times!'})
        }
        else {
          this.setState({userTrainDestination: filteredTrainArray})
        }
    }


    else if(trainLine === 'G') {
      console.log('green line chosen')
      filteredTrainArray = trainArray.filter((extractor) => {
        return extractor !== fiftyFourthCermak && extractor !== Loop && extractor !== forestPark && extractor !== Kimball
        && extractor !== Midway && extractor !== ohare
        })
        if(filteredTrainArray.length === 0) {
          console.log('no upcoming times')
          this.setState({noUpcomingTime: 'No upcoming times!'})
        }
        else {
          this.setState({userTrainDestination: filteredTrainArray})
        }
    }

    else if(trainLine === 'Pink') {
      console.log('pink line chosen')
      filteredTrainArray = trainArray.filter((extractor) => {
        return extractor !== HarlemLake && extractor !== CottageGrove && extractor !== Ashland63 && extractor !== forestPark
        && extractor !== Midway && extractor !== ohare && extractor !== Kimball && extractor !== Linden && extractor !== Howard
        })
        if(filteredTrainArray.length === 0) {
          console.log('no upcoming times')
          this.setState({noUpcomingTime: 'No upcoming times!'})
        }
        else {
          this.setState({userTrainDestination: filteredTrainArray})
        }
      this.setState({userTrainDestination: filteredTrainArray})
    }

    else if(trainLine === 'Org') {
      console.log('orange line chosen')
      filteredTrainArray = trainArray.filter((extractor) => {
        return extractor !== CottageGrove && extractor !== ninetyFifthDanRyan && extractor !== Howard 
        && extractor !== HarlemLake && extractor !== Ashland63 && extractor !== fiftyFourthCermak && extractor !== Kimball
        && extractor !== forestPark && extractor !== ohare
        })
        if(filteredTrainArray.length === 0) {
          console.log('no upcoming times')
          this.setState({noUpcomingTime: 'No upcoming times!'})
        }
        else {
          this.setState({userTrainDestination: filteredTrainArray})
        }
      this.setState({userTrainDestination: filteredTrainArray})
    }

    else if(trainLine === 'Y') {
      console.log('yellow line chosen')
      let isolateYellow;
      let uniqueYellow;
      let yellowTrainArray;
      let yellowDestinationArray = [];
      //isolate yellow line only
      let yellowTrains = arrivalTimes.filter((train) => {
        return train.rt === 'Y'
      })
      for(let i = 0; i < yellowTrains.length; i++) {
        yellowDestinationArray.push(yellowTrains[i].destNm);
      }
      uniqueYellow = [...new Set( yellowDestinationArray)];
      yellowTrainArray = uniqueYellow.sort();
        if(yellowTrainArray.length === 0) {
          console.log('no upcoming times')
          this.setState({noUpcomingTime: 'No upcoming times!'})
        }
        else {
          console.log('running here')
          console.log(yellowTrainArray)
          this.setState({userTrainDestination: yellowTrainArray})
        }
    }
    this.setState({trainArrivals: arrivalTimes});
    this.setState({stationName: name})
    this.setState({loading : false})

    //ROSEMONT OHARE
    this.setState({ohareRosemont: '40820'});
    let rosemontTracker = this.state.ohareRosemont;
    const res2 = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://lapi.transitchicago.com/api/1.0/ttarrivals.aspx?key=${process.env.REACT_APP_TRAIN_TRACKER_KEY}&mapid=${rosemontTracker}&outputType=json`);
    let convert2 = res2.data;
    let rosemontTimes = convert2['ctatt']['eta'];
    let destination = 'Forest Park';
    let rosemontArray = rosemontTimes.filter((direction) => {
      return destination.trim() === direction.destNm
    })
    this.setState({rosemontNexusArray: rosemontArray})
  }

  chooseTrainDestination = async (destination) => {
    //let routesFromStorage = localStorage.getItem('savedRoutes');
    this.setState({chosenDestination: destination})
    let trainArrivalArray = this.state.trainArrivals.filter((arrival) => {
      return destination === arrival.destNm
    })

    this.setState({trainArrivalTimes: trainArrivalArray })
    this.setState({revealTrainStops: false})
    this.setState({revealTrainDestination: false})
    this.setState({revealTrainStops: false})
    this.setState({revealTrainArrivalTimes: true})

    let trainStopsFromStorage = localStorage.getItem('savedTrainStops');
    let savedTrainStops;

    if(trainStopsFromStorage === null) {
      savedTrainStops = [];
    }
    else {
      savedTrainStops = JSON.parse(trainStopsFromStorage);
    }

     //SAVE TRAIN
     let userTrain = {};
     userTrain.trainLine = this.state.chosenTrainLine;
     userTrain.stationName = this.state.stationName.trim();
     userTrain.stationNumber = this.state.trainStationNumber;
     userTrain.background = this.state.trainStopsBackground;
     userTrain.destination = destination;
    //IF DESTINATION IS SEE TRAIN DO NOT ALLOW TO STORE 
    // if(userTrain.destination !== 'See train') {
    //   savedTrainStops.push(userTrain);
    // }
     //IF STOP ALREADY EXISTS IN DATABASE DO NOT ALLOW TO SAVE
     let checkMatch = savedTrainStops.some(stop => stop.stationName === userTrain.stationName && stop.destination === userTrain.destination)
     if(checkMatch !== true && userTrain.destination !== 'See train') {
       savedTrainStops.push(userTrain)
     }
    //savedTrainStops.push(userTrain);
    localStorage.setItem('savedTrainStops', JSON.stringify(savedTrainStops));
    this.setState({savedTrainStops : savedTrainStops})
    this.setState({openTabOne: true})
  
  }

  refreshTrains = async (linked) => {
    this.setState({loading : true})
    console.log(linked)
    let stationNumber = this.state.trainStationNumber;
    console.log(stationNumber);
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://lapi.transitchicago.com/api/1.0/ttarrivals.aspx?key=${process.env.REACT_APP_TRAIN_TRACKER_KEY}&mapid=${stationNumber}&outputType=json`);
    let convert = res.data;
    console.log(convert);
    let destinationArray = [];
    let arrivalTimes = convert['ctatt']['eta'];
    console.log(arrivalTimes)
    let destination = this.state.chosenDestination;
    console.log(destination)
    let trainArrivalArray = arrivalTimes.filter((arrival) => {
      return destination === arrival.destNm
    })
    console.log(trainArrivalArray)
    this.setState({trainArrivalTimes: trainArrivalArray })
    this.setState({loading : false})
    //ROSEMONT OHARE
    this.setState({ohareRosemont: '40820'});
    let rosemontTracker = this.state.ohareRosemont;
    const res2 = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://lapi.transitchicago.com/api/1.0/ttarrivals.aspx?key=${process.env.REACT_APP_TRAIN_TRACKER_KEY}&mapid=${rosemontTracker}&outputType=json`);
    let convert2 = res2.data;
    let rosemontTimes = convert2['ctatt']['eta'];
    console.log(rosemontTimes)
    let destination2 = 'Forest Park';
    let rosemontArray = rosemontTimes.filter((direction) => {
      return destination2.trim() === direction.destNm
    })
    this.setState({rosemontNexusArray: rosemontArray})
    console.log(rosemontArray)
  }

  deleteTrainStop = (destination,trainStop,background,index) => {
    let stateTrainStops = this.state.savedTrainStops;
    //ADD INDEX NUMBER PROPERTY TO STATE ARRAY
    stateTrainStops.forEach((unit,index) => {
      unit.indexNumber = index
    })
    
    //DELETING FROM STATE
    let filterStops = stateTrainStops.filter((filtered) => {
      return filtered.indexNumber !== index
    })

    this.setState({savedTrainStops: filterStops})
    //DELETING FROM LOCAL STORAGE
    let dataBaseStops = JSON.parse(localStorage.getItem('savedTrainStops'));
    console.log(dataBaseStops)
    let filterDataBase = stateTrainStops.filter((filtered) => {
      return filtered.indexNumber !== index
    })
    localStorage.setItem('savedTrainStops', JSON.stringify(filterDataBase))
    if(this.state.savedTrainStops.length === 0) {
      this.setState({openTabOne: false})
    } 


  }

  trainStopLink = async (trainStop) => {
    this.setState({loading: true})
    this.setState({trainStopsBackground:'#fff'})
    this.setState({badgeContainer: true})
    this.setState({revealTrainArrivals: false})
    this.setState({revealTrainStops: false})
    this.setState({revealTrainArrivalTimes: true});
    this.setState({revealTrainDestination: false})
    this.setState({trainStationNumber:trainStop.stationNumber})
    this.setState({chosenDestination:trainStop.destination})
    this.setState({revealBusArrivals: false})
    this.setState({revealBusRoutes: false})
    this.setState({revealBusDirections: false})
    this.setState({revealNoService: false})
    this.setState({revealBusStops: false})
    this.setState({revealAlerts: false})
    let destination = trainStop.destination;
    const res = await axios.get(`https://secret-mesa-75716.herokuapp.com/http://lapi.transitchicago.com/api/1.0/ttarrivals.aspx?key=${process.env.REACT_APP_TRAIN_TRACKER_KEY}&mapid=${trainStop.stationNumber}&outputType=json`);
    let convert = res.data;
    let arrivalTimes = convert['ctatt']['eta'];
    let trainArrivalArray = arrivalTimes.filter((arrival) => {
      return destination === arrival.destNm
    })
    let refinedTrainArrival = trainArrivalArray.filter((arrival) => {
      return trainStop.trainLine === arrival.rt
    })
    this.setState({trainArrivalTimes: trainArrivalArray});
    this.setState({stationName: trainStop.stationName});
    this.setState({trainStopsBackground: trainStop.background});
    this.setState({loading: false})
  }

  revealServices = async() => {
    this.setState({badgeContainer: true})
    this.setState({revealAlerts: true})
    this.setState({serviceClicked: true})
    //this.setState({loading : true})
    // const res = await axios.get(`http://www.transitchicago.com/api/1.0/routes.aspx?type=bus&outputType=JSON`)
    // let convert = res.data;
    // console.log(convert)
    //let directions = convert['bustime-response']['directions'];
    //console.log(directions)
  
    //this.setState({loading : false})
  }

  aboutClicked = () => {
    this.setState({openTabOne: false})
  }

  

 

  render () {

    const {busRoutes, loading, chosenRoute, ctaBusStops, snapShot, openTabOne, routeCounter, serviceMessage,chosenStop} = this.state

    return (
      <Router>
        <div className='container'  ref={this.containerRef}>
          <Navbar snapShot={snapShot} chosenRoute={chosenRoute} openTabOne={openTabOne} 
          routeCounter={routeCounter} savedRoutes={this.state.savedRoutes} savedStops={this.state.savedStops} 
          direction={this.state.userDirection} removeStops={this.removeStops} newStop={this.newStop} 
          deleteStop={this.deleteStop} 
          closeStops={this.closeStops} stopLink={this.stopLink} 
          savedTrainStops = {this.state.savedTrainStops} deleteTrainStop={this.deleteTrainStop} 
          trainStopLink={this.trainStopLink} openStops={this.openStops} 
          referenceToContainer={this.containerRef['current']} referenceToBody={this.state.body}
          serviceClicked={this.state.serviceClicked} aboutClicked={this.aboutClicked}></Navbar>
          <Switch>
              <Route exact path='/' render={(props) => (
                <Fragment>
                  <div id='badgeContainer' style={{display: this.state.badgeContainer ? 'none': 'block'}} >
                    <div id='busBadge' onClick={this.revealBusRoutes} >
                      <div id='busIcon'>
                        <i className="fa fa-bus" aria-hidden="true"></i>
                      </div>
                      <div id='busText'>
                          Bus Routes
                      </div>
                    </div>
                    <div id='trainBadge' onClick={this.revealTrain}>
                      <div id='trainIcon'>
                      <i className="fa fa-subway" aria-hidden="true"></i>
                      </div>
                      <div id='trainText'>
                        Train Routes
                      </div>
                    </div>
                    <div id='alertBadge' onClick={this.revealServices}>
                      <div id='alertIcon'>
                       <i className="fa fa-bullhorn" aria-hidden="true"></i>
                      </div>
                      <div id='alertText'>
                        Service Bulletins
                      </div>
                    </div>
                   
                  </div>
                  {this.state.revealBusRoutes ? <BusRoutes clickBusRoute={this.clickBusRoute}  
                  busRoutes={busRoutes} loading={loading} root={this.state.root}></BusRoutes> : null}
                  {this.state.revealBusDirections ? <BusDirections 
                  chosenRoute={chosenRoute} loading={loading} 
                  chosenDirections={this.state.chosenDirections} chooseDirection={this.chooseDirection} 
                  root={this.state.root} background={this.state.busBackground}></BusDirections> : null}
                  {this.state.revealBusStops? <BusStops ctaBusStops={ctaBusStops}  chosenRoute={chosenRoute} 
                  loading={loading} chooseStop={this.chooseStop}></BusStops> : null}
                  {this.state.revealBusArrivals? <BusArrivals root={this.state.root} chosenRoute={chosenRoute} arrivingBuses={this.state.arrivingBuses} 
                  loading={this.state.loading} revealNoService={this.state.revealNoService}
                   refreshTime={this.refreshTime} newStop={this.newStop} chosenStop={this.state.chosenStop} 
                   referenceToContainer={this.containerRef['current']} busCoordinates={this.state.busCoordinates}></BusArrivals>:null}
                  {this.state.revealNoService? <NoService serviceMessage={serviceMessage}></NoService> : null}
                  {this.state.revealTrainArrivals? <TrainLines chooseLine={this.chooseLine}></TrainLines>: null}
                  {this.state.revealTrainStops? <TrainStops trainStops={this.state.trainStops} 
                  background={this.state.trainStopsBackground} chooseTrainStop={this.chooseTrainStop}></TrainStops>: null}
                  {this.state.revealTrainDestination ? <TrainDestination background={this.state.trainStopsBackground} 
                  trainDirection={this.state.userTrainDestination} 
                  chooseTrainDestination={this.chooseTrainDestination}></TrainDestination>: null}
                  {this.state.revealTrainArrivalTimes? 
                  <TrainArrivals stationName={this.state.stationName} 
                  trainArrivalTimes={this.state.trainArrivalTimes} 
                  background={this.state.trainStopsBackground} refreshTrains ={this.refreshTrains} newStop={this.newStop} root={this.state.root} 
                  referenceToContainer={this.containerRef['current']} loading={this.state.loading}></TrainArrivals>: null}
                  {this.state.revealAlerts? 
                  <ServiceBulletin ref={this.serviceRef} referenceToContainer={this.containerRef['current']} root={this.state.root} closeAlerts={this.state.closeAlertDropDown}></ServiceBulletin>: null} 
                </Fragment>
              )}>

              </Route>
              {/* <Route exact path='/about' referenceToContainer={this.containerRef['current']} component={About}></Route> */}
              <Route exact path='/about' render={() => (
                <About onClick={this.aboutClicked} referenceToContainer={this.containerRef['current']}></About>
              )}></Route>
            </Switch>
        </div>
      </Router>
    )
  } 
}

export default App;
