import '../../App.css';
import React,{Component,Fragment} from 'react';
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types';
import BusDirectionItem from './BusDirectionItem';


class BusDirections extends Component {

    
   state = {
      
   }

   componentDidMount() {
       
   }

   static propTypes = {
       loading: PropTypes.bool.isRequired,
       chosenDirections: PropTypes.array.isRequired,
   }

   chooseDirection = (direction) => {
    this.props.chooseDirection(direction);
   }

  

   render() {
       const {loading,chosenDirections, chosenRoute, root,background} = this.props;
       root.style.backgroundColor = background; 


       if(loading) {
        return (
            <Spinner></Spinner>
        )

       }

        else {
            return (
                <div id='busDirection'>
                    <h1 style={routeStyle}>{chosenRoute.rtnm}</h1>
                   {chosenDirections.map((direction,item) => (
                       <BusDirectionItem key = {item} direction={direction} chooseDirection={this.chooseDirection}></BusDirectionItem>
                   ))}
                </div>
            ) 
        }

   }
}


const routeStyle = {
    fontFamily: "Raleway",
    fontSize: '1.4em',
    textAlign: 'center',
    color: '#18447b'
}



export default BusDirections;
